import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';

class WithAnimation extends React.PureComponent {
  static propTypes = {
    /**
     * Children to render. It must be CSSTransition component with unique key prop.
     */
    children: PropTypes.element,
  };

  state = {
    childKey: this.props.children ? this.props.children.key : undefined,
  };

  // ;;life ----------------------------------------------------------------------------------------

  componentDidUpdate(prevProps) {
    if (!prevProps.children && this.props.children) {
      this.setState({ childKey: this.props.children.key });
    }
  }

  // ;;events --------------------------------------------------------------------------------------

  changeChildKey = () => {
    const currentChild = this.props.children || {};

    if (this.state.childKey !== currentChild.key) {
      window.requestAnimationFrame(() => {
        this.setState({ childKey: currentChild.key });
      });
      // setTimeout(() => {
      //   this.setState({ childKey: currentChild.key });
      // });
    }
  };

  // ;;render --------------------------------------------------------------------------------------

  renderChildren(children) {
    if (!children) return null;

    return React.cloneElement(children, {
      onExited: this.changeChildKey,
    });
  }

  render() {
    const currentChild = this.props.children || {};
    const children = this.state.childKey === currentChild.key ? this.props.children : null;

    return <TransitionGroup>{this.renderChildren(children)}</TransitionGroup>;
  }
}

export default WithAnimation;
