import keys from '../config/keys';

class KeyDetector {
  isEscape(e) {
    return e.key === keys.esc.key || e.keyCode === keys.esc.keyCode;
  }

  isEnter(e) {
    return e.key === keys.enter.key || e.keyCode === keys.enter.keyCode;
  }

  isSpace(e) {
    return e.key === keys.space.key || e.keyCode === keys.space.keyCode;
  }
}

export default new KeyDetector();
