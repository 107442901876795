import React from 'react';
import PropTypes from 'prop-types';

class MainBody extends React.Component {
  render() {
    return (
      <div className="main-body">
        {this.props.children}
      </div>
    );
  }
}

export default MainBody;
