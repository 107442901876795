import React from 'react';
import Cookies from 'js-cookie';

// Config
import { urls } from '../../config';

// Components
import Button from '../../../../frontend-base/js/components/button/button';
import Divider from '../../../../frontend-base/js/components/divider/divider';
import Message from '../../../../frontend-base/js/components/message/message';
import IslandTitle from '../../../../frontend-base/js/components/island-title/island-title';
import IslandText from '../../../../frontend-base/js/components/island-text/island-text';
import Break from '../../../../frontend-base/js/components/break/break';

class AcceptTermsForm extends React.Component {
  static propTypes = {};

  csrfToken = document.head.querySelector('[name=csrf-token]')?.content;
  acceptTermsInputName = `user[${Cookies.get('location')}_terms_accepted]`;

  state = {
    message: null,
  };

  // ;;render --------------------------------------------------------------------------------------

  renderMessage() {
    if (!this.state.message) return null;

    return (
      <React.Fragment>
        <Message {...this.state.message} />
        <Divider height={2} />
      </React.Fragment>
    );
  }

  renderText() {
    return (
      <IslandText>
        Чтобы продолжить, согласитесь на обработку ваших персональных данных и примите условия <a href={urls.privacyPolicyUrl} target="_blank">политики конфиденциальности</a> и <a href={urls.agreementUrl} target="_blank">пользовательского соглашения.</a>
      </IslandText>
    );
  }

  renderSubmitButton() {
    return (
      <Button processing={this.state.processing} type="submit">
        Я принимаю условия
      </Button>
    );
  }

  renderDeclineLink() {
    return (
      <a href={urls.api.declineTerms} class="button button_special" rel="noreferrer">
        <span class="button__text">Я не принимаю условия</span>
      </a>
    );
  }

  render() {
    return (
      <form action={urls.api.acceptTerms} method="POST">
        <input type="hidden" name="authenticity_token" value={this.csrfToken} />
        <input type="hidden" name={this.acceptTermsInputName} value="true" />
        {this.renderMessage()}
        {this.renderText()}
        <Divider height={2} />
        {this.renderSubmitButton()}
        <Divider height={3} />
        <Break phoneLargeIndents={3} />
        <Divider height={3} />
        {this.renderDeclineLink()}
      </form>
    );
  }
}

export default AcceptTermsForm;
