import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Services
import popupManager from '../../services/popup-manager';

// HOC
import WithAnimation from '../../service-components/with-animation';

// Components
import Header from '../header/header';
import HeaderIconButton from '../header-icon-button/header-icon-button';
import PopupTitle from '../popup-title/popup-title';

class PopupContainer extends React.Component {
  static propTypes = {
    /**
     * Component to render
     */
    component: PropTypes.element,

    /**
     * Google Search API key
     */
    googleSearchKey: PropTypes.string,

    /**
     * User absolute links for header
     */
    headerAbsoluteLinks: PropTypes.bool,

    /**
     * Login path for header link
     */
    loginPath: PropTypes.string,

    /**
     * Menu structure
     */
    menuStructure: PropTypes.array,

    /**
     * Current type of popup
     */
    popupType: PropTypes.string,

    /**
     * Profile path
     */
    profilePath: PropTypes.string,

    /**
     * Popup title
     */
    title: PropTypes.string,

    /**
     * Transition duration
     */
    transitionDuration: PropTypes.number,

    /**
     * User data
     */
    userData: PropTypes.object,
  };

  // ;;events --------------------------------------------------------------------------------------

  handleCloseButton = () => {
    popupManager.close();
  };

  // ;;render --------------------------------------------------------------------------------------

  renderHeader() {
    if (this.props.popupType !== 'menu') return null;

    return (
      <CSSTransition
        classNames="fade-in"
        in
        key="popup-container-header"
        timeout={this.props.transitionDuration}>
        <Header
          absoluteLinks={this.props.headerAbsoluteLinks}
          googleSearchKey={this.props.googleSearchKey}
          isPopup
          loginAbsoluteLink
          loginPath={this.props.loginPath}
          menuStructure={this.props.menuStructure}
          profilePath={this.props.profilePath}
          transitionDuration={this.props.transitionDuration}
          userData={this.props.userData}
        />
      </CSSTransition>
    );
  }

  renderCloseButton() {
    return (
      <div className="popup-container__close events-always">
        <HeaderIconButton handleClick={this.handleCloseButton} iconType="close" />
      </div>
    );
  }

  renderTitle() {
    if (!this.props.title) return null;

    return (
      <CSSTransition
        classNames="fade-in"
        in
        key={`popup-container-title-${this.props.popupType}`}
        timeout={this.props.transitionDuration}>
        <PopupTitle>{this.props.title}</PopupTitle>
      </CSSTransition>
    );
  }

  renderContent() {
    if (this.props.popupType === 'menu') return null;

    return (
      /* Component special for timeout animation if Auth open inside popup */
      <div className="popup-container__content">
        <WithAnimation>{this.renderTitle()}</WithAnimation>
        {/* Components except main menu (main menu contain header) */}
        {this.props.component}
      </div>
    );
  }

  render() {
    return (
      <div className="popup-container" id="popupContainer">
        {this.renderCloseButton()}
        <TransitionGroup>{this.renderHeader()}</TransitionGroup>
        {this.renderContent()}
      </div>
    );
  }
}

export default PopupContainer;
