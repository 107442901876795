const deepFreeze = require('deep-freeze');

const rawConstants = require('./constants');
const rawMetrics = require('./metrics');
const rawTexts = require('./texts');
const rawUrls = require('./urls');

const rawRailsConfig = require('../../config.js.erb').default;

const constants = deepFreeze(rawConstants);
const metrics = deepFreeze(rawMetrics);
const texts = deepFreeze(rawTexts);
const urls = deepFreeze(rawUrls);
const railsConfig = deepFreeze(rawRailsConfig);

module.exports = {
  constants,
  metrics,
  texts,
  urls,
  railsConfig,
};
