import React from 'react';
import PropTypes from 'prop-types';

// Components
import MainBody from '../../components/main-body/main-body';
import AcceptTermsForm from '../../components/accept-terms-form/accept-terms-form';

class AcceptTermsPage extends React.PureComponent {
  static propTypes = {};

  render() {
    return (
      <div className="accept-terms-form">
        <MainBody>
          <AcceptTermsForm />
        </MainBody>
      </div>
    );
  }
}

export default AcceptTermsPage;
