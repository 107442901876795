import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Input extends React.Component {
  static propTypes = {
    /**
     * Configure autocomplete
     */
    autoComplete: PropTypes.string,

    /**
     * Set initial focus to the field
     */
    autoFocus: PropTypes.bool,

    /**
     * Disabled state
     */
    disabled: PropTypes.bool,

    /**
     * Error state
     */
    error: PropTypes.bool,

    /**
     * Height 100%
     */
    fullHeight: PropTypes.bool,

    /**
     * Handler for input change event
     */
    handleChange: PropTypes.func,

    /**
     * Onblur input
     */
    onBlur: PropTypes.func,

    /**
     * Onfocus input
     */
    onFocus: PropTypes.func,

    /**
     * ID for label binding
     */
    id: PropTypes.string,

    /**
     * Vertical align center
     */
    middle: PropTypes.bool,

    /**
     * Input name
     */
    name: PropTypes.string,

    /**
     * Input placeholder
     */
    placeholder: PropTypes.string,

    /**
     * Input for search
     */
    search: PropTypes.bool,

    /**
     * Input with small height
     */
    thin: PropTypes.bool,

    /**
     * Input type
     */
    type: PropTypes.string,

    /**
     * Input value
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * If need place for button on right side
     */
    withButton: PropTypes.bool,
  };

  $input = React.createRef();

  $textarea = React.createRef();

  // ;;life ----------------------------------------------------------------------------------------

  componentDidMount() {
    if (this.props.autoFocus) {
      this.$input.current.focus();
    }
  }

  renderTextarea = () => {
    return (
      <textarea
        autoComplete={this.props.autoComplete}
        className={classNames({
          textarea: true,
          textarea_error: this.props.error,
          textarea_thin: this.props.thin,
          textarea_large: this.props.large,
          textarea_middle: this.props.middle,
          textarea_resizable: this.props.resizable,
        })}
        disabled={this.props.disabled}
        id={this.props.id}
        name={this.props.name}
        onChange={this.props.handleChange}
        onBlur = {this.props.onBlur}
        onFocus = {this.props.onFocus}
        ref={this.$textarea}
        placeholder={this.props.placeholder}
        value={this.props.value}
        maxLength={this.props.maxLength}
      ></textarea>
    )
  }

  render() {
    if (this.props.type == 'textarea') return this.renderTextarea();

    return (
      <input
        autoComplete={this.props.autoComplete}
        className={classNames({
          input: true,
          input_error: this.props.error,
          'input_full-height': this.props.fullHeight,
          input_search: this.props.search,
          'input_with-button': this.props.withButton,
          input_thin: this.props.thin,
          input_middle: this.props.middle,
        })}
        disabled={this.props.disabled}
        id={this.props.id}
        name={this.props.name}
        onChange={this.props.handleChange}
        onBlur = {this.props.onBlur}
        onFocus = {this.props.onFocus}
        ref={this.$input}
        type={this.props.type}
        placeholder={this.props.placeholder}
        value={this.props.value}
      />
    );
  }
}

export default Input;
