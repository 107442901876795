import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Break extends React.PureComponent {

  static propTypes = {

    /**
     * Phone large side indents value in spacers
     */
    phoneLargeIndents: PropTypes.number,

    /**
     * Desktop small side indents value in spacers
     */
    desktopSmallIndents: PropTypes.number,
  };

  render() {
    return (
      <hr
        className={classNames({
          break: true,
          'break_phone-large-3': this.props.phoneLargeIndents === 3,
          'break_desktop-small-3': this.props.desktopSmallIndents === 3,
        })}
      />
    );
  }
}

export default Break;
