import React from 'react';
import PropTypes from 'prop-types';

class PopupTitle extends React.PureComponent {
  static propTypes = {

  };

  render() {
    return (
      <h2 className="popup-title">
        {this.props.children}
      </h2>
    );
  }
}

export default PopupTitle;
