import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js/validate';
import axios from 'axios';

// Config
import { urls, texts } from '../../config';

// Services
import InputManager from '../../../../frontend-base/js/services/input-manager';

// Components
import Button from '../../../../frontend-base/js/components/button/button';
import Divider from '../../../../frontend-base/js/components/divider/divider';
import IslandText from '../../../../frontend-base/js/components/island-text/island-text';
import Input from '../../../../frontend-base/js/components/input/input';
import Message from '../../../../frontend-base/js/components/message/message';
import IslandTitle from '../../../../frontend-base/js/components/island-title/island-title';
import Break from '../../../../frontend-base/js/components/break/break';

class ResetPassForm extends React.Component {
  static propTypes = {};

  actions = {
    showResendButton: 'showResendButton',
  };

  inputNames = {
    email: 'email',
  };

  constraints = {
    email: {
      email: {
        message: texts.errors.invalidEmail,
      },
    },
  };

  state = {
    processing: false,
    message: null,
    resendProcessing: false,
    lastUsedEmail: null,
    success: null,
  };

  inputManager = new InputManager(this, {
    inputs: [this.inputNames.email],
  });

  // ;;events --------------------------------------------------------------------------------------

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.checkFormValidity();

    if (errors) return this.showError(errors[0]);

    this.sendForm();
  };

  handleResendClick = () => {
    this.setState({
      resendProcessing: true,
    });

    setTimeout(() => {
      this.sendRequest(this.state.lastUsedEmail);
    }, 1500);
  };

  // ;;inner ---------------------------------------------------------------------------------------

  sendForm() {
    const email = this.inputManager.getValue(this.inputNames.email);
    this.setState({
      processing: true,
      lastUsedEmail: email,
    });

    this.sendRequest(email);
  }

  async sendRequest(email) {
    try {
      const response = await axios.post(urls.api.resetPassword, {
        user: {
          email,
        },
      });

      const error = response.data?.error?.message;

      if (error) throw error;

      this.showSuccess(response.data.success);
    } catch (e) {
      this.setState({
        lastUsedEmail: null,
      });
      this.showError(e.response?.data?.error?.message || e);
    }
  }

  showSuccess(data) {
    if (!data) return;

    this.inputManager.setValue(this.inputNames.email, '');

    this.setState({
      processing: false,
      resendProcessing: false,
      success: {
        message: data.message,
      },
    });
  }

  showError(msg) {
    this.setState({
      processing: false,
      resendProcessing: false,
      message: {
        content: typeof msg === 'string' ? msg : texts.errors.default,
        error: true,
      },
    });
  }

  // ;;compute -------------------------------------------------------------------------------------

  checkFormValidity() {
    return validate(
      {
        email: this.inputManager.getValue(this.inputNames.email),
      },
      this.constraints,
      {
        format: 'flat',
        fullMessages: false,
      }
    );
  }

  isFormValid() {
    return !this.checkFormValidity();
  }

  // ;;render --------------------------------------------------------------------------------------

  renderMessageAction(action) {
    if (action === this.actions.showResendButton) {
      return (
        <React.Fragment>
          <Divider height={1} />
          <Button
            handleClick={this.handleResendClick}
            insideMessage
            processing={this.state.resendProcessing}
            special>
            Выслать письмо ещё раз
          </Button>
        </React.Fragment>
      );
    }

    return null;
  }

  renderMessageContent() {
    return (
      <React.Fragment>
        {this.state.message.content}
        {this.renderMessageAction(this.state.message.action)}
      </React.Fragment>
    );
  }

  renderMessage() {
    if (!this.state.message) return null;

    return (
      <React.Fragment>
        <Message
          content={this.renderMessageContent()}
          error={this.state.message.error}
          warning={this.state.message.warning}
        />
        <Divider height={2} />
      </React.Fragment>
    );
  }

  renderTitle() {
    return <IslandTitle>Восстановление пароля</IslandTitle>;
  }

  renderText() {
    return (
      <IslandText>
        Чтобы восстановить пароль, укажите адрес электронной почты, который вы использовали
        при регистрации
      </IslandText>
    );
  }

  renderInput() {
    return (
      <Input
        error={this.state.message?.error}
        handleChange={this.inputManager.handleChange}
        name={this.inputNames.email}
        placeholder="Электронная почта"
        value={this.inputManager.getValue(this.inputNames.email)}
      />
    );
  }

  renderSubmitButton() {
    return (
      <Button disabledStyle={!this.isFormValid()} processing={this.state.processing} type="submit">
        Получить инструкции
      </Button>
    );
  }

  renderFormContent() {
    if (this.state.success) return null;

    return (
      <React.Fragment>
        {this.renderText()}
        <Divider height={2} />
        {this.renderInput()}
        <Divider height={2} />
        {this.renderSubmitButton()}
      </React.Fragment>
    );
  }

  renderSuccess() {
    if (!this.state.success) return null;

    return (
      <React.Fragment>
        <IslandText>{this.state.success.message}</IslandText>
        <Divider height={1} />
        <Button
          handleClick={this.handleResendClick}
          processing={this.state.resendProcessing}
          special
          specialWithSpinner
          spinnerDark>
          Выслать письмо ещё раз
        </Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderMessage()}
        {this.renderTitle()}
        <Divider height={2} />
        {this.renderFormContent()}
        {this.renderSuccess()}
        <Divider height={3} />
        <Break phoneLargeIndents={3} />
        <Divider height={3} />
        <Button absoluteUrl special url="mailto:help@dev.by">
          Нужна помощь
        </Button>
      </form>
    );
  }
}

export default ResetPassForm;
