import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Spinner extends React.PureComponent {
  static propTypes = {
    /**
     * True if spinner inside Message component
     */
    insideMessage: PropTypes.bool,

    /**
     * Light spinner
     */
    light: PropTypes.bool,

    /**
     * Small spinner
     */
    small: PropTypes.bool,

    /**
     * Spinner is used to show page loading
     */
    pageLoader: PropTypes.bool,
  };

  // ;;render --------------------------------------------------------------------------------------

  render() {
    return (
      <div
        className={classNames({
          spinner: true,
          spinner_small: this.props.small,
          spinner_light: this.props.light,
          spinner_full: this.props.pageLoader,
          spinner_message: this.props.insideMessage,
        })}>
        <div className="spinner__inner" />
      </div>
    );
  }
}

export default Spinner;
