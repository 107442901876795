import React from 'react';
import PropTypes from 'prop-types';

// Components
import MainBody from '../../components/main-body/main-body';
import GoogleSignUpForm from '../../components/google-sign-up-form/sign-up-form';

class GoogleSignUpPage extends React.PureComponent {
  static propTypes = {};

  render() {
    return (
      <div className="google-sign-up-form">
        <MainBody>
          <GoogleSignUpForm />
        </MainBody>
      </div>
    );
  }
}

export default GoogleSignUpPage;
