class BrowserDetect {
  init() {
    const userAgentElement = !window.isServer ? navigator.userAgent : '';
    const appVersionElement = !window.isServer ? navigator.appVersion : '';

    const dataBrowser = [
      { string: userAgentElement, subString: 'Edge', identity: 'MS Edge' },
      { string: userAgentElement, subString: 'MSIE', identity: 'IE' },
      { string: userAgentElement, subString: 'Trident', identity: 'IE' },
      { string: userAgentElement, subString: 'Firefox', identity: 'Firefox' },
      { string: userAgentElement, subString: 'Opera', identity: 'Opera' },
      { string: userAgentElement, subString: 'OPR', identity: 'Opera' },

      { string: userAgentElement, subString: 'Chrome', identity: 'Chrome' },
      { string: userAgentElement, subString: 'Safari', identity: 'Safari' },
    ];

    return {
      browser: this.searchString(dataBrowser) || 'Other',
      version:
        this.searchVersion(userAgentElement) || this.searchVersion(appVersionElement) || 'Unknown',
      isIE: this.searchString(dataBrowser) && this.searchString(dataBrowser) === 'IE',
      isEdge: this.searchString(dataBrowser) && this.searchString(dataBrowser) === 'MS Edge',
      isSafari: this.searchString(dataBrowser) && this.searchString(dataBrowser) === 'Safari',
    };
  }
  searchString(data) {
    for (let i = 0; i < data.length; i += 1) {
      const dataString = data[i].string;
      this.versionSearchString = data[i].subString;

      if (dataString.indexOf(data[i].subString) !== -1) {
        return data[i].identity;
      }
    }
  }
  searchVersion(dataString) {
    const index = dataString.indexOf(this.versionSearchString);
    if (index === -1) {
      return;
    }

    const rv = dataString.indexOf('rv:');
    if (this.versionSearchString === 'Trident' && rv !== -1) {
      return parseFloat(dataString.substring(rv + 3));
    }
    return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));

  }
}

export default new BrowserDetect().init();
