import React from 'react';
import PropTypes from 'prop-types';

// Configs
import metrics from '../../config/metrics';

class Divider extends React.Component {
  static propTypes = {

    /**
     * Height in spacers
     */
    height: PropTypes.number,
  };

  // ;;compute -------------------------------------------------------------------------------------

  getHeightPx() {
    return this.props.height * metrics.spacer;
  }

  // ;;render --------------------------------------------------------------------------------------

  render() {
    return (
      <hr
        className="divider"
        style={{ marginBottom: `${this.getHeightPx()}px` }}
      />
    );
  }
}

export default Divider;
