import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Route, Switch } from 'react-router-dom';

// Routes
import routes from '../../routes';

// Services
import { navigator } from '../../../../frontend-base/js/services/navigator';

class Main extends React.Component {
  static propTypes = {};

  // ;;compute -------------------------------------------------------------------------------------

  isSlimForm() {
    return window.location.href.indexOf('from_jobs')>0;
  }

  isFullFormWithToBack() {
    return window.location.href.indexOf('from_wish_popup')>0;
  }

  // ;;render --------------------------------------------------------------------------------------

  renderRouteWithSubRoutes(route, index) {
    return (
      <Route
        key={index}
        exact={route.exact || false}
        path={route.path}
        render={props => (
          // Pass the sub-routes down to keep nesting
          <route.component
            {...props}
            path={this.props.path}
            routes={route.routes}
            isSlimForm = {this.isSlimForm()}
            isFullFormWithToBack = {this.isFullFormWithToBack()}
          />
        )}
      />
    );
  }

  renderRoutes() {
    return routes.map((route, index) => this.renderRouteWithSubRoutes(route, index));
  }

  render() {

    return (
      <div className={classNames({
        'main': true,
        'main_has-to-back': this.isSlimForm() || this.isFullFormWithToBack(),
      })}>
        <div className="main__container">
          {this.renderRoutes()}
        </div>
      </div>
    );
  }
}

export default Main;
