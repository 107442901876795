const railsConfig = require('../../config.js.erb').default;

module.exports = {
  loginPath: '/@/welcome',
  agreementUrl: railsConfig.agreement_url,
  privacyPolicyUrl: railsConfig.privacy_policy_url,
  idInfoUrl: 'https://devby.io/pages/verification',
  routes: {
    index: '/',
    deleteAccount: '/edit',
    verify: '/verifications/new',
    signIn: '/hello',
    signUp: '/welcome',
    resetPassword: '/password',
    newPassword: '/password/edit',
    acceptTermsPage: '/accept-terms',
    googleSignUpPage: '/welcome-google',
  },
  api: {
    signIn: '/@/hello',
    signUp: '/@/welcome',
    resetPassword: '/@/password',
    newPassword: '/@/password',
    verifySms: '/@/verifications',
    verifyCode: '/@/verifications',
    verifyDestroy: '/@/verifications',
    google: '/users/auth/google_oauth2',
    googleAcceptTerms: '/@/welcome-google',
    googleSignUpForm: '/@/welcome-google',
    acceptTerms: '/@/accept-terms',
    declineTerms: '/@/accept-terms/decline',
  },
};
