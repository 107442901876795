let breakpointListener;

/**
 * Listen to mobile/desktop breakpoint change and publish this event
 *
 * @example
 * import breakpointListener from './breakpoint-listener';
 *
 * breakpointListener.subscribe({
 *   id: 'layout',
 *   cb: (isMobile) => { this.setState({ isMobile }) },
 * });
 *
 * breakpointListener.unsubscribe('id');
 */
class BreakpointListener {
  // Calculate initial state
  isMobile = setTimeout(() => {
    this.check();
  }, 0);

  // Init listeners array
  listeners = [];

  constructor(breakpoint) {
    if (__SERVER__) return;

    this.breakpoint = breakpoint;

    // Add resize listener to window
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    // Add current device state
    const isMobile = this.check();

    // If change current device state
    if (isMobile !== this.isMobile) {
      this.isMobile = isMobile;
      this.publish();
    }
  };

  check() {
    if (__SERVER__) return;

    // Cross-browser calculate current viewport width
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return viewportWidth < this.breakpoint;
  }

  /**
   * Add listener
   * @param data {object} Listener data
   * @param data.id {string} Listener ID
   * @param data.cb {function} Listener callback
   */
  subscribe(data) {
    // Test for unique id call
    if (this.listeners.length && this.listeners.find(listener => listener.id === data.id)) return;

    this.listeners.push(data);
  }

  /**
   * Remove listeners
   * @param id {string} Listener ID
   */
  unsubscribe(id) {
    this.listeners.forEach((listener, index) => {
      if (listener.id === id) {
        // Remove unique resize listener
        window.removeEventListener('resize', listener.cb);
        this.listeners.splice(index, 1);
      }
    });
  }

  /**
   * Emit all listeners
   */
  publish() {
    this.listeners.forEach((listener) => {
      listener.cb(this.isMobile);
    });
  }
}

const BreakpointListenerConstructor = {
  init(breakpoint) {
    breakpointListener = new BreakpointListener(breakpoint);
  },
};

export { breakpointListener, BreakpointListenerConstructor };
