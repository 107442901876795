import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Route, Switch } from 'react-router-dom';

// Config
import { urls } from '../../config';

// Services
import { navigator } from '../../../../frontend-base/js/services/navigator';

// Components
import FadeInAnimation from '../../../../frontend-base/js/components/fade-in-animation/fade-in-animation';
import MainBody from '../../components/main-body/main-body';
import ButtonToBack from '../../../../frontend-base/js/components/button-to-back/button-to-back';
import Icon from '../../../../frontend-base/js/components/icon/icon';

class AuthPage extends React.PureComponent {
  static propTypes = {
    /**
     * Router config
     */
    routes: PropTypes.array,
  };

  navItems = [
    {
      title: 'Вход',
      route: urls.routes.signIn,
    },
    {
      title: 'Регистрация',
      route: urls.routes.signUp,
    },
  ];

  // ;;compute -------------------------------------------------------------------------------------

  isCurrentRoute(route) {
    return navigator.getCurrentLocation().pathname === route;
  }

  getParamsFromUrl = () => {
    if (this.props.isFullFormWithToBack) return '?' + window.location.href.split('?').slice(1);

    return '';
  }

  // ;;render --------------------------------------------------------------------------------------

  renderNavItems() {

    return this.navItems.map(item => (
      <Link
        className={classNames({
          'auth-page__nav-link': true,
          'auth-page__nav-link_active': this.isCurrentRoute(item.route),
        })}
        key={item.title}
        to={item.route + this.getParamsFromUrl()}>
        {item.title}
      </Link>
    ));
  }

  renderNav() {
    if (this.props.isSlimForm) return null;

    return <ul className="auth-page__nav">{this.renderNavItems()}</ul>;
  }

  renderRouteWithSubRoutes(route) {
    return (
      <Route
        key={route.path}
        exact={route.exact || false}
        path={route.path}
        render={props => (
          // Pass the sub-routes down to keep nesting
          <FadeInAnimation>
            <route.component {...props} path={this.props.path} isSlimForm = {this.props.isSlimForm} isFullFormWithToBack = {this.props.isFullFormWithToBack} />
          </FadeInAnimation>
        )}
      />
    );
  }

  renderRoutes() {
    if (!this.props.routes) return null;

    return this.props.routes.map((route, index) => this.renderRouteWithSubRoutes(route, index));
  }

  renderButtonToBack() {
    if (this.props.isSlimForm) {
      return (
        <div className="wrap-button-to-back">
          <ButtonToBack>
            Войдите, чтобы отправить отклик.
          </ButtonToBack>
        </div>
      );
    };

    if (this.props.isFullFormWithToBack) {
      return (
        <div className="wrap-button-to-back">
          <ButtonToBack isFullFormWithToBack = {true}>
            Войдите или зарегистрируйтесь, чтобы сохранить ваши пожелания.
          </ButtonToBack>
        </div>
      );
    }

    return null;
  }

  render() {

    return (
      <div className="auth-page">
        {this.renderButtonToBack()}
        {this.renderNav()}
        <MainBody>
          <Switch location={this.props.location}>{this.renderRoutes()}</Switch>
        </MainBody>
      </div>
    );
  }
}

export default AuthPage;
