module.exports = {
  esc: {
    key: 'Escape',
    keyCode: 27,
  },
  enter: {
    key: 'Enter',
    keyCode: 13,
  },
  space: {
    key: 'Space',
    keyCode: 32,
  },
};
