import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { animateScroll as scroll } from 'react-scroll';
import { withRouter } from 'react-router';

// Config
import {
  constants, metrics
} from './config';

// Services
import DOMClassName from '../../frontend-base/js/services/dom-classname';
import popupManager from '../../frontend-base/js/services/popup-manager';
import { BreakpointListenerConstructor } from '../../frontend-base/js/services/breakpoint-listener';
import { navigator, NavigatorConstructor } from '../../frontend-base/js/services/navigator';

// Components
import PopupContainer from '../../frontend-base/js/components/popup-container/popup-container';
import Layout from './components/layout/layout';

class App extends React.Component {
  static propTypes = {};

  constructor(props) {
    super();

    popupManager.__init({
      openPopup: this.openPopup,
      closePopup: this.closePopup,
    });

    BreakpointListenerConstructor.init(metrics.breakpointPhoneLarge);

    NavigatorConstructor.init({
      history: props.history,
      location: props.location,
      match: props.match,
    });

    this.state = {
      pageYOffset: 0,
      popupType: null,
      popupData: {},
    };
  }

  // ;;public --------------------------------------------------------------------------------------

  /**
   * Open popup
   * @param options {object} Options
   * @param options.isInsidePopupTransition {boolean} True if called from Popup component
   * @param options.type {string} Unique name of popup
   */
  openPopup = (options) => {
    if (options.isInsidePopupTransition) {
      this.setState({
        popupType: options.type,
        popupData: options.data,
      });
    } else {
      this.setState({
        pageYOffset: window.pageYOffset,
        popupType: options.type,
        popupData: options.data,
      });
    }
  };

  closePopup = () => {
    if (!this.state.popupType) return;

    this.setState(
      {
        popupType: null,
      },
      () => {
        DOMClassName.remove('popup-open');

        scroll.scrollTo(this.state.pageYOffset, {
          duration: 0,
        });
      }
    );
  };

  // ;;events --------------------------------------------------------------------------------------

  handleEnteredPopup = () => {
    this.addStylesForOpenedPopup();
    this.scrollToTop();
  };

  // ;;inner ---------------------------------------------------------------------------------------

  addStylesForOpenedPopup() {
    DOMClassName.add('popup-open');
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  // ;;render --------------------------------------------------------------------------------------

  renderPopupContainer() {
    if (!this.state.popupType) return null;

    return [
      <CSSTransition
        classNames="fade-in"
        in
        key="popup-container"
        timeout={metrics.transitionDurationDefault}
        onEntered={this.handleEnteredPopup}>
        <PopupContainer
          googleSearchKey={constants.googleSearchKey}
          headerAbsoluteLinks
          popupData={this.state.popupData}
          popupType={this.state.popupType}
          transitionDuration={metrics.transitionDurationDefault}
        />
      </CSSTransition>,
    ];
  }

  render() {
    return (
      <React.Fragment>
        <Layout />
        <TransitionGroup component={null}>{this.renderPopupContainer()}</TransitionGroup>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
