module.exports = {
  errors: {
    default: 'Что-то пошло не так. Попробуйте ещё раз.',
    invalidEmail: 'Введите корректный адрес электронной почты.',
    invalidPhone: 'Введите корректный номер мобильного телефона.',
    emptyEmail: 'Введите адрес электронной почты или имя пользователя.',
    emptyPassword: 'Введите пароль.',
    emptyCode: 'Введите код.',
    invalidPassword: 'Пароль не подходит. Используйте для пароля не менее 6 символов.',
    nonMatchingPassword: 'Пароли не совпадают.',
    acceptTerms: 'Вы не можете зарегистрироваться, не приняв условия.',
    usernameTooLong: 'Максимальная длина юзернейма — 20 символов.',
    phoneTooLong: 'Введите корректный номер мобильного телефона.',
  },
  success: {
    passwordChanged: 'Ваш пароль изменён.',
  },
};
