import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import axios from 'axios';

// Config
import { urls, texts } from '../../config';

// Services
import InputManager from '../../../../frontend-base/js/services/input-manager';

// Components
import Button from '../../../../frontend-base/js/components/button/button';
import ButtonWithIcon from '../../../../frontend-base/js/components/button-with-icon/button-with-icon';
import Divider from '../../../../frontend-base/js/components/divider/divider';
import Icon from '../../../../frontend-base/js/components/icon/icon';
import Input from '../../../../frontend-base/js/components/input/input';
import InputGroup from '../../../../frontend-base/js/components/input-group/input-group';
import InputWithButton from '../../../../frontend-base/js/components/input-with-button/input-with-button';
import Message from '../../../../frontend-base/js/components/message/message';
import IconButton from '../../../../frontend-base/js/components/icon-button/icon-button';

class SignInForm extends React.Component {
  static propTypes = {};

  inputNames = {
    email: 'email',
    password: 'password',
  };

  constraints = {
    email: {
      length: {
        minimum: 1,
        message: texts.errors.emptyEmail,
      },
    },
    password: {
      length: {
        minimum: 1,
        message: texts.errors.emptyPassword,
      },
    },
  };

  state = {
    processing: false,
    message: null,
  };

  inputManager = new InputManager(this, {
    inputs: [this.inputNames.email, this.inputNames.password],
  });

  componentDidMount() {
    let _this = this;
    this.inputManager.setValue(this.inputNames.email, _this.valueInputEmail());
  }


  // ;;events --------------------------------------------------------------------------------------

  handleLoginGoogle = () => {
    window.location = urls.api.google;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.checkFormValidity();

    if (errors) return this.showError(errors[0]);

    this.sendForm();
  };

  // ;;inner ---------------------------------------------------------------------------------------

  async sendForm() {
    this.setState({
      processing: true,
    });

    try {
      const response = await axios.post(urls.api.signIn, {
        user: {
          login: this.inputManager.getValue(this.inputNames.email),
          password: this.inputManager.getValue(this.inputNames.password),
        },
      });

      const error = response.data?.error?.message;

      if (error) throw error;


      const redirectUrl = response.data?.success?.redirect_url;

      let action = '';

      if (response.data?.success?.action == 'showCodeForm') {
        action = '?' + response.data?.success?.action;
      }

      if (redirectUrl) window.location = redirectUrl + action;
    } catch (e) {
      this.showError(e.response?.data?.error?.message || e);
    }
  }

  showError(msg) {
    this.setState({
      processing: false,
      message: {
        content: typeof msg === 'string' ? msg : texts.errors.default,
        error: true,
      },
    });
  }


  // ;;compute -------------------------------------------------------------------------------------

  isFullForm() {
    return !this.props.isSlimForm;
  }

  checkFormValidity() {
    return validate(
      {
        email: this.inputManager.getValue(this.inputNames.email),
        password: this.inputManager.getValue(this.inputNames.password),
      },
      this.constraints,
      {
        format: 'flat',
        fullMessages: false,
      }
    );
  }

  getEmailUser() {
    if (this.props.isSlimForm) return this.props.location.search.split('email=').pop();
  }

  valueInputEmail() {
    if (this.props.isSlimForm) {
      return this.getEmailUser();
    };

    return this.inputManager.getValue(this.inputNames.email);
  }

  valueAutoCompleteInputPassword() {
    if(this.props.isSlimForm) return 'new-password';

    return 'on';
  }

  valueAutoCompleteInpus() {
    if(this.props.isSlimForm) return 'off';

    return 'on';
  }

  isFormValid() {
    return !this.checkFormValidity();
  }

  isAutoFocusInput() {
    return this.props.isSlimForm;
  }

  // ;;render --------------------------------------------------------------------------------------

  renderMessage() {
    if (!this.state.message) return null;

    return (
      <React.Fragment>
        <Message {...this.state.message} />
        <Divider height={2} />
      </React.Fragment>
    );
  }

  renderInputs() {
    return (
      <InputGroup error={this.state.message?.error}>
        <Input
          handleChange={this.inputManager.handleChange}
          name={this.inputNames.email}
          placeholder="Эл. почта или юзернейм"
          value={this.valueInputEmail()}
          autoComplete={this.valueAutoCompleteInpus()}
        />
        <InputWithButton>
          <Input
            handleChange={this.inputManager.handleChange}
            name={this.inputNames.password}
            placeholder="Пароль"
            type="password"
            value={this.inputManager.getValue(this.inputNames.password)}
            autoComplete={this.valueAutoCompleteInputPassword()}
            autoFocus={this.isAutoFocusInput()}
            withButton
          />
          <IconButton
            iconType="help"
            title="Восстановление пароля"
            insideInput
            url={urls.routes.resetPassword}
          />
        </InputWithButton>
      </InputGroup>
    );
  }

  renderSocialButtons() {
    if (this.isFullForm()) {
      return ([
        <Divider height={3} key="01"/>,
        <ButtonWithIcon key="02">
          <Button handleClick={this.handleLoginGoogle} secondary>
            Войти с помощью Google
          </Button>
          <Icon iconType="google" />
        </ButtonWithIcon>
      ]);
    }

    return null;
  }

  renderSubmitButton() {
    return (
      <Button disabledStyle={!this.isFormValid()} processing={this.state.processing} type="submit">
        Войти
      </Button>
    );
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} autoComplete={this.valueAutoCompleteInpus()}>
        {this.renderMessage()}
        {this.renderInputs()}
        <Divider height={3} />
        {this.renderSubmitButton()}
        {this.renderSocialButtons()}
      </form>
    );
  }
}

export default SignInForm;
