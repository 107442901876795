import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NavLink } from 'react-router-dom';

// Services
import popupManager from '../../services/popup-manager';

// Components
import Icon from '../icon/icon';
import HeaderIconButton from '../header-icon-button/header-icon-button';
import HeaderNav from '../header-nav/header-nav';
import HeaderSearch from '../header-search/header-search';

class Header extends React.Component {
  static propTypes = {
    /**
     * Use absolute links
     */
    absoluteLinks: PropTypes.bool,

    /**
     * Google search key
     */
    googleSearchKey: PropTypes.string,

    /**
     * True if Header instance inside popup
     */
    isPopup: PropTypes.bool,

    /**
     * Use absolute link for login button
     */
    loginAbsoluteLink: PropTypes.bool,

    /**
     * Path to login page
     */
    loginPath: PropTypes.string,

    /**
     * Menu structure
     */
    menuStructure: PropTypes.array,

    /**
     * Show minimal content
     */
    minimal: PropTypes.bool,

    /**
     * Profile path
     */
    profilePath: PropTypes.string,

    /**
     * Root URL for absolute link
     */
    rootUrl: PropTypes.string,

    /**
     * Transition duration
     */
    transitionDuration: PropTypes.number,

    /**
     * User data
     */
    userData: PropTypes.object,

    /**
     * With max width
     */
    isNarrow: PropTypes.bool,

    /**
     * Vacancy count
     */
    vacancyCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  };

  constructor() {
    super();

    this.state = {
      showSearch: false,
    };
  }

  // ;;events --------------------------------------------------------------------------------------

  handleHideSearch = () => {
    this.setState({ showSearch: false });
  };

  handleLogoClick = () => {
    if (this.props.isPopup) popupManager.close();
  };

  handleMenuButton = () => {
    popupManager.open({ type: 'menu' });
  };

  handleShowSearch = () => {
    this.setState({ showSearch: true });
  };

  // ;;compute -------------------------------------------------------------------------------------

  getSearchTransitionKey() {
    return this.props.isPopup ? 'search-popup' : 'search';
  }

  // ;;render --------------------------------------------------------------------------------------

  renderLogo() {
    const Tag = this.props.absoluteLinks ? 'a' : NavLink;
    const tagProps = this.props.absoluteLinks
      ? {
        href: this.props.rootUrl,
      }
      : {
        to: '/',
        exact: true,
      };

    return (
      <Tag className="header__logo-link" onClick={this.handleLogoClick} {...tagProps}>
        <Icon iconType="logo" />
      </Tag>
    );
  }

  renderNav() {
    if (this.props.minimal) return null;

    // CSS animated delay fadeIn if open popup
    // CSS animated fadeOut if open search in popup
    return (
      <HeaderNav
        handleClickSearch={this.handleShowSearch}
        isPopup={this.props.isPopup}
        menuStructure={this.props.menuStructure}
        showSearch={this.state.showSearch}
        vacancyCount={this.props.vacancyCount}
      />
    );
  }

  renderSearch() {
    if (!this.state.showSearch) return null;

    return (
      <CSSTransition
        classNames="fade-in-top"
        in
        key={this.getSearchTransitionKey()}
        timeout={this.props.transitionDuration}>
        <HeaderSearch
          googleSearchKey={this.props.googleSearchKey}
          handleClickOutside={this.handleHideSearch}
          isPopup={this.props.isPopup}
        />
      </CSSTransition>
    );
  }

  renderAuthImageButton() {
    if (!this.props.userData) return null;

    return (
      <React.Fragment>
        <a className="header__auth-image-wrapper" href={this.props.profilePath}>
          <span
            className="header__auth-image"
            style={{ backgroundImage: `url(${this.props.userData.userpic})` }}
          />
        </a>
        {/* Maybe needed later */}
        {this.renderAuthMessages(0)}
      </React.Fragment>
    );
  }

  renderAuthIconButton() {
    if (this.props.userData) return null;

    return (
      <HeaderIconButton
        absoluteUrl={this.props.loginAbsoluteLink}
        iconType="user"
        url={this.props.loginPath}
      />
    );
  }

  renderAuthMessages(sum) {
    if (!sum) return null;

    return <div className="header__auth-messages">{sum}</div>;
  }

  renderAuth() {
    if (this.props.minimal) return null;

    return (
      <div className="header__auth">
        {this.renderAuthImageButton()}
        {this.renderAuthIconButton()}
      </div>
    );
  }

  renderMenuBtn() {
    if (this.props.minimal) return null;

    return (
      <div className="header__controls-button header__controls-button_menu">
        <HeaderIconButton handleClick={this.handleMenuButton} iconType="menu" />
      </div>
    );
  }

  renderSearchBtn() {
    if (this.props.minimal || this.state.showSearch) return null;

    return (
      <CSSTransition
        classNames="fade-out-top"
        in
        key="header-search-btn"
        timeout={this.props.transitionDuration}>
        <div className="header__controls-button header__controls-button_search">
          <HeaderIconButton handleClick={this.handleShowSearch} iconType="search" />
        </div>
      </CSSTransition>
    );
  }

  render() {
    return (
      <header
        className={classNames({
          header: true,
          header_popup: this.props.isPopup,
          header_narrow: this.props.isNarrow,
        })}>
        <div className="header__content">
          <div className="header__main">
            {this.renderLogo()}
            <div className="header__general">
              {this.renderNav()}
              <TransitionGroup component={null}>{this.renderSearch()}</TransitionGroup>
            </div>
          </div>
          <div className="header__aside">
            <TransitionGroup>{this.renderSearchBtn()}</TransitionGroup>
            {this.renderAuth()}
            {this.renderMenuBtn()}
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
