import React from 'react';

class InputWithButton extends React.Component {
  render() {
    return (
      <div className="input-with-button">
        {this.props.children[0]}
        <div className="input-with-button__button">{this.props.children[1]}</div>
      </div>
    );
  }
}

export default InputWithButton;
