// Services
import keyDetector from './key-detector';

class PopupManager {
  constructor(options) {
    Object.assign(this, options);

    this.isContainerOpen = false;
  }

  open = (options) => {
    this.openPopup(options);

    if (!this.isContainerOpen) {
      this.addListeners();

      this.isContainerOpen = true;
    }
  };

  close = () => {
    this.closePopup();
    this.removeListeners();

    this.isContainerOpen = false;
  };

  closeByEsc = (e) => {
    if (keyDetector.isEscape(e)) {
      this.close();
    }
  };

  addListeners() {
    document.addEventListener('keydown', this.closeByEsc);
  }

  removeListeners() {
    document.removeEventListener('keydown', this.closeByEsc);
  }
}

export default {
  __init(app) {
    const popupManager = new PopupManager({
      openPopup: app.openPopup,
      closePopup: app.closePopup,
    });

    this.open = popupManager.open;
    this.close = popupManager.close;
  },
};
