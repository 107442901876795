import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Components
import Icon from '../icon/icon';
import Spinner from '../spinner/spinner';

class Button extends React.Component {
  static defaultProps = {
    type: 'button',
  };

  static propTypes = {
    /**
     * Type of url
     */
    absoluteUrl: PropTypes.bool,

    /**
     * Big size
     */
    big: PropTypes.bool,

    /**
     * Center align
     */
    center: PropTypes.bool,

    /**
     * Button check state
     * (priority of processing if prop.check and prop.processing true)
     */
    check: PropTypes.bool,

    /**
     * Button danger style
     */
    danger: PropTypes.bool,

    /**
     * Button disabled state
     */
    disabled: PropTypes.bool,

    /**
     * Button disabled style
     */
    disabledStyle: PropTypes.bool,

    /**
     * Click handler
     */
    handleClick: PropTypes.func,

    /**
     * True if button inside Message component
     */
    insideMessage: PropTypes.bool,

    /**
     * True if button inside Popover component
     */
    insidePopover: PropTypes.bool,

    /**
     * Button with margin bottom on small screens
     */
    marginBottom: PropTypes.bool,

    /**
     * Mono style for button text
     */
    monoStyle: PropTypes.bool,

    /**
     * Button processing state
     * (priority of processing if prop.check and prop.processing true)
     */
    processing: PropTypes.bool,

    /**
     * Secondary style type
     */
    secondary: PropTypes.bool,

    /**
     * Special style type
     */
    special: PropTypes.bool,

    /**
     * Adjust special style for button with spinner
     */
    specialWithSpinner: PropTypes.bool,

    /**
     * Small size
     */
    small: PropTypes.bool,

    /**
     * Use dark spinner
     */
    spinnerDark: PropTypes.bool,

    /**
     * Button type
     */
    type: PropTypes.string,

    /**
     * Link URL
     */
    url: PropTypes.string,

     /**
     * Target
     */
    target: PropTypes.string,

    /**
     * Link rel attribute
     */
    rel: PropTypes.string,
  };

  elementProps = {
    onClick: this.props.handleClick,
  };

  // ;;compute -------------------------------------------------------------------------------------

  isRouterLink() {
    return this.props.url && !this.props.absoluteUrl;
  }

  getClassNames() {
    return classNames({
      button: true,
      button_big: this.props.big,
      button_center: this.props.center,
      button_danger: this.props.danger,
      button_processing: this.props.processing,
      button_secondary: this.props.secondary,
      button_special: this.props.special,
      'button_special-with-spinner': this.props.specialWithSpinner,
      button_small: this.props.small,
      button_disabled: this.props.disabledStyle,
      button_message: this.props.insideMessage,
      button_popover: this.props.insidePopover,
      'button_auto-size': this.props.autoSize,
      'button_margin-bottom': this.props.marginBottom,
    });
  }

  isSpinnerLight() {
    return !this.props.secondary && !this.props.spinnerDark;
  }

  // ;;render --------------------------------------------------------------------------------------

  renderCheck() {
    if (!this.props.check || this.props.processing) return null;

    return (
      <div className="button__check">
        <Icon iconType="check" />
      </div>
    );
  }

  renderSpinner() {
    if (!this.props.processing) return null;

    return <Spinner insideMessage={this.props.insideMessage} light={this.isSpinnerLight()} small />;
  }

  renderButton() {
    return (
      <button
        {...this.elementProps}
        className={this.getClassNames()}
        disabled={this.props.disabled}
        type={this.props.type}>
        <span className="button__content">
          <span
            className={classNames({
              button__text: true,
              button__text_mono: this.props.monoStyle,
            })}>
            {this.props.children}
          </span>
          {this.renderSpinner()}
          {this.renderCheck()}
        </span>
      </button>
    );
  }

  renderLink() {
    const ElementName = this.isRouterLink() ? Link : 'a';
    const linkProps = {
      ...this.elementProps,
      className: this.getClassNames(),
      rel: this.props.rel,
      target: this.props.target,
      [this.isRouterLink() ? 'to' : 'href']: this.props.url,
    };

    return (
      <ElementName {...linkProps}>
        <span className="button__content">
          <span className="button__text">{this.props.children}</span>
        </span>
      </ElementName>
    );
  }

  render() {
    if (this.props.url) return this.renderLink();

    return this.renderButton();
  }
}

export default Button;
