import React from 'react';
import PropTypes from 'prop-types';

// Services
import DOMClassName from '../../services/dom-classname';
import { navigator } from '../../services/navigator';

// Components
import Icon from '../icon/icon';

class ErrorPageComponent extends React.PureComponent {
  static propTypes = {
    /**
     * Error object
     */
    error: PropTypes.object,

    /**
     * URL for static assets
     */
    staticPath: PropTypes.string,
  };

  // ;;life ----------------------------------------------------------------------------------------

  componentDidMount() {
    DOMClassName.remove('popup-open');
    DOMClassName.add('error-page');
    navigator.resetScroll();
  }

  componentWillUnmount() {
    DOMClassName.remove('error-page');
  }

  // ;;inner ---------------------------------------------------------------------------------------

  getErrorCode() {
    return (this.props.error && this.props.error.response && this.props.error.response.status) || 0;
  }

  getErrorText() {
    switch (this.getErrorCode()) {
    case 401:
      return 'Страница недоступна';
    case 403:
      return 'Страница недоступна';
    case 404:
      return 'Страница не найдена';
    default:
      return 'Ошибка';
    }
  }

  // ;;render --------------------------------------------------------------------------------------

  render() {
    return (
      <div className="error-page">
        <header className="error-page__header">
          <a href="/" className="error-page__logo-link">
            <Icon iconType="logo" />
          </a>
          <h1 className="error-page__title">
            Error {this.getErrorCode()}&nbsp;
            <br />
            #{this.getErrorText()}
          </h1>
        </header>
        <img
          className="error-page__image"
          src={`${this.props.staticPath}/web/error@2x.gif`}
          alt=""
        />
      </div>
    );
  }
}

export default ErrorPageComponent;
