import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class InputGroup extends React.Component {
  static propTypes = {
    /**
     * Error state
     */
    error: PropTypes.bool,
  };

  // ;;render --------------------------------------------------------------------------------------

  renderInputs() {
    return React.Children.map(this.props.children, child => {
      if (child != null) {
        return (
          <li
            className={classNames({
              'input-group__item': true,
              'input-group__item_error': this.props.error,
            })}>
            {child}
          </li>
        )
      }
    });
  }

  render() {
    return <ul className="input-group">{this.renderInputs()}</ul>;
  }
}

export default InputGroup;
