import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Components
import Icon from '../icon/icon';

class IconButton extends React.Component {
  static propTypes = {
    absoluteUrl: PropTypes.bool,
    handleClick: PropTypes.func,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconType: PropTypes.string.isRequired,

    /**
     * Add inner space to button
     */
    innerSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    insideInput: PropTypes.bool,
    isBlue: PropTypes.bool,
    isLight: PropTypes.bool,

    /**
     * Target for <a>
     */
    target: PropTypes.string,

    /**
     * Button with small height
     */
    thin: PropTypes.bool,

    /**
     * Title attribute for button
     */
    title: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  };

  static defaultProps = {
    type: 'button',
  };

  // ;;compute -------------------------------------------------------------------------------------

  getElementProps() {
    return {
      className: classNames({
        'icon-button': true,
        'icon-button_blue': this.props.isBlue,
        'icon-button_light': this.props.isLight,
        'icon-button_thin': this.props.thin,
        'icon-button_inside-input': this.props.insideInput,
        [`icon-button_space-${this.props.innerSpace}`]: this.props.innerSpace,
      }),
      onClick: this.props.handleClick,
      title: this.props.title,
    };
  }

  isRouterLink() {
    return this.props.url && !this.props.absoluteUrl;
  }

  // ;;render --------------------------------------------------------------------------------------

  renderIcon() {
    return <Icon iconType={this.props.iconType} size={this.props.iconSize} />;
  }

  renderButton() {
    return (
      <button {...this.getElementProps()} type={this.props.type}>
        {this.renderIcon()}
      </button>
    );
  }

  renderLink() {
    const ElementName = this.isRouterLink() ? Link : 'a';
    const linkProps = {
      ...this.getElementProps(),
      [this.isRouterLink() ? 'to' : 'href']: this.props.url,
      target: this.props.target,
    };

    return <ElementName {...linkProps}>{this.renderIcon()}</ElementName>;
  }

  render() {
    if (!this.props.url) return this.renderButton();

    return this.renderLink();
  }
}

export default IconButton;
