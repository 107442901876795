import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function OnClickOutside(WrappedComponent) {
  return class extends React.Component {

    static propTypes = {

      /**
       * Handle click outside
       */
      handleClickOutside: PropTypes.func.isRequired,
    };

    $wrappedComponent = React.createRef();

    // ;;life --------------------------------------------------------------------------------------

    componentDidMount() {
      document.addEventListener('click', this.handleClickOutside);
      document.addEventListener('keyup', this.handleClickOutside);
      document.querySelector('html').classList.add('no-events');
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
      document.removeEventListener('keyup', this.handleClickOutside);
      document.querySelector('html').classList.remove('no-events');
    }

    // ;;events ------------------------------------------------------------------------------------

    handleClickOutside = (e) => {
      this.clickOutside(e);
    };

    // ;;inner -------------------------------------------------------------------------------------

    clickOutside(e) {
      if (this.isKeyupEvent(e)) {
        if (this.isEscapeKey(e)) {
          this.clickOutsideInner(e);
        }
      } else {
        this.clickOutsideInner(e);
      }
    }

    clickOutsideInner(e) {
      if (!this.isChildOfWrappedComponent(e.target)) {
        e.preventDefault();

        this.props.handleClickOutside(e);
      }
    }

    // ;;compute -----------------------------------------------------------------------------------

    isChildOfWrappedComponent(node) {
      const DOMElementWrappedComponent = ReactDOM.findDOMNode(this.$wrappedComponent.current);

      return DOMElementWrappedComponent.contains(node);
    }

    isKeyupEvent(e) {
      return e.type === 'keyup';
    }

    isEscapeKey(e) {
      return e.key === 'Escape';
    }

    // ;;render --------------------------------------------------------------------------------------

    render() {

      return (
        <WrappedComponent
          {...this.props}
          ref={this.$wrappedComponent}>
          {this.props.children}
        </WrappedComponent>
      );
    }
  }
}

export default OnClickOutside;
