



const railsConfig = {
  host:                     "devby.io",
  protocol:                 "https",
  recaptcha_key:            "6LeMvlAfAAAAAEbMfZlQq3WDyiaEcq3uNYDLs5-q",
  bugsnag_frontend_key:     "f000ac322c65a10897875210a81a5818",
  lenta_static_assets_path: "https://static.devby.io",
  login_path:               "/@/hello",
  logout_path:              "/@/bye",
  profile_path:             "/my",
  new_company_url:          "https://companies.devby.io/new",
  stop_bot_url:             "/bots/unbind",
  bot_url:                  "https://t.me/jobsdevbybot",
  privacy_policy_url:       "https://devby.io/pages/privacy-policy",
  agreement_url:            "https://devby.io/pages/polzovatelskoe-soglashenie",
  errorPageUrl:             "/errors"
};

export default railsConfig
