import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../icon/icon';

class IslandTitle extends React.Component {
  static propTypes = {
    /**
     * Title button element
     */
    titleButton: PropTypes.element,

    /**
     * Aside button element
     */
    asideButton: PropTypes.element,

    /**
     * True if button inside Popover component
     */
    insidePopover: PropTypes.bool,

    /**
     * Multiline on mobile
     */
    multiline: PropTypes.bool,

    /**
     * align-items: center
     */
    alignCenter: PropTypes.bool,

    /**
     * font-weight: normal
     */
    fontMedium: PropTypes.bool,

     /**
     * Icon type
     */
    iconType: PropTypes.string,

    /**
     * Icon color
     */
    fill: PropTypes.string,
  };

  // ;;render --------------------------------------------------------------------------------------

  renderTitleButton() {
    if (!this.props.titleButton) return null;

    return <div className="island-title__title-button">{this.props.titleButton}</div>;
  }

  renderAsideButton() {
    if (!this.props.asideButton) return null;

    return <div className="island-title__aside-button">{this.props.asideButton}</div>;
  }

  renderСircleIcon() {
    if (!this.props.iconType) return null;

    return (
      <div className='island-title__circle-icon'>
        <Icon iconType={this.props.iconType} fill={this.props.fill} />
      </div>
    )
  }

  renderTitleContent() {
    if (this.props.fontMedium) {
      return (
        <span
          className={classNames({
            'island-title__title': true,
            'island-title__title_medium': this.props.fontMedium,
            'island-title__title_popover': this.props.insidePopover,
          })}
        >
          {this.props.children}
        </span>
      )
    }

    return (
      <strong
        className={classNames({
          'island-title__title': true,
          'island-title__title_popover': this.props.insidePopover,
        })}>
        {this.props.children}
      </strong>
    )
  }

  render() {
    return (
      <div
        className={classNames({
          'island-title': true,
          'island-title_multiline': this.props.multiline,
          'island-title_align-center': this.props.alignCenter,
        })}>
        {this.renderСircleIcon()}
        <div className="island-title__with-title">
          {this.renderTitleContent()}
          {this.renderTitleButton()}
        </div>
        {this.renderAsideButton()}
      </div>
    );
  }
}

export default IslandTitle;
