import React from 'react';
import axios from 'axios';
import validate from 'validate.js';
import Cookies from 'js-cookie';

// Config
import { urls, texts, railsConfig } from '../../config';

// Services
import InputManager from '../../../../frontend-base/js/services/input-manager';

// Components
import Button from '../../../../frontend-base/js/components/button/button';
import CheckboxText from '../../../../frontend-base/js/components/checkbox-text/checkbox-text';
import Divider from '../../../../frontend-base/js/components/divider/divider';
import Message from '../../../../frontend-base/js/components/message/message';
import IconButton from '../../../../frontend-base/js/components/icon-button/icon-button';
import Input from '../../../../frontend-base/js/components/input/input';
import InputGroup from '../../../../frontend-base/js/components/input-group/input-group';
import InputWithButton from '../../../../frontend-base/js/components/input-with-button/input-with-button';
import IslandTitle from '../../../../frontend-base/js/components/island-title/island-title';
import SwitchButtons from '../../../../frontend-base/js/components/switch-buttons/switch-buttons';

// Init GoogleSignUpForm class
class GoogleSignUpForm extends React.Component {
  static propTypes = {};

  csrfToken = document.head.querySelector('[name=csrf-token]')?.content;

  state = {
    processing: false,
    message: null,
  };

  inputNames = {
    acceptTerms: `user[${Cookies.get('location')}_terms_accepted]`,
    subscription: 'user[subscription_required]',
    username: 'user[username]',
    phone: 'user[requested_phone]',
    role: 'user[role]'
  };

  inputManager = new InputManager(this, {
    inputs: [
      this.inputNames.acceptTerms,
      this.inputNames.username,
      this.inputNames.phone,
      this.inputNames.subscription,
      this.inputNames.role,
    ],
    initialValues: {
      [this.inputNames.subscription]: true,
    },
  });

  constraints = {
    acceptTerms: {
      inclusion: {
        within: [true],
        message: texts.errors.acceptTerms,
      },
    },
  }

  $phoneInput = React.createRef();

  componentDidMount() {
    this.autoCompleteUsernameFromGoogle();
  }

  // ;;inner ---------------------------------------------------------------------------------------

  async sendForm() {
    this.setState({
      processing: true,
    });

    try {
      const response = await axios.post(urls.api.googleSignUpForm, {
        user: {
          local_terms_accepted: this.getValueCheckboxBool(this.inputManager.getValue('user[local_terms_accepted]')),
          global_terms_accepted: this.getValueCheckboxBool(this.inputManager.getValue('user[global_terms_accepted]')),
          subscription_required: this.getValueCheckboxBool(this.inputManager.getValue(this.inputNames.subscription)),
          role: this.inputManager.getValue(this.inputNames.role),
          username: this.inputManager.getValue(this.inputNames.username),
          requested_phone: this.inputManager.getValue(this.inputNames.phone).substring(1),
          authenticity_token: this.csrfToken,
        },
      });
      const error = response.data?.error?.message;

      if (error) throw error;

      const redirectUrl = response.data?.success?.redirect_url;

      let action = '';

      if (response.data?.success?.action == 'showCodeForm') {
        action = '?' + response.data?.success?.action;
      }

      if (redirectUrl) window.location = redirectUrl + action;
    } catch (e) {
      if (e?.response?.status && e?.response?.status == 500) {
        window.location = railsConfig.errorPageUrl + '?' + 'status=500';
      } else {
        this.showError(e.response?.data?.error?.message || e);
      }
    }
  }

  showError(msg) {
    this.setState({
      processing: false,
      message: {
        content: typeof msg === 'string' ? msg : texts.errors.default,
        error: true,
      },
    });
  }

  // ;;events --------------------------------------------------------------------------------------

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.checkFormValidity();

    if (errors) return this.showError(errors[0]);

    this.sendForm();
  };

  // ;;compute -------------------------------------------------------------------------------------

  getValueCheckboxBool = (valueCheckbox) => {
    return Boolean(valueCheckbox);
  }

  handleChangePhone = (e) => {
    if (!/^[+]{1}[0-9]*$/.test(e.target.value)) return null;

    this.inputManager.handleChange(e);
  };

  checkFormValidity() {
    return validate(
      {
        acceptTerms: this.inputManager.getValue(this.inputNames.acceptTerms),
        subscription: this.inputManager.getValue(this.inputNames.subscription),
        hr: this.inputManager.getValue(this.inputNames.hr),
        username: this.inputManager.getValue(this.inputNames.username),
        phone: this.inputManager.getValue(this.inputNames.phone),
      },
      this.constraints,
      {
        format: 'flat',
        fullMessages: false,
      }
    );
  }

  isFormValid() {
    return !this.checkFormValidity();
  }

  autoCompleteUsernameFromGoogle = () => {
    let email = document.head.querySelector('meta[name="email"]').getAttribute("content");

    this.setUsernameFromEmail(email);
  }

  setUsernameFromEmail = (email) => {
    let username = '';

    if (email.indexOf('@') > 0)  {
      username = email.split('@')[0];
    }

    this.inputManager.setValue(this.inputNames.username, username);
  }

  handleFocus = () => {
    this.setOnFocusValuePhone(() => {
      this.setCaretAtEnd(this.$phoneInput.current.$input.current);
    })
  }

  setOnFocusValuePhone = (callback) => {
    if (this.inputManager.getValue(this.inputNames.phone).length == 0) {
      this.inputManager.setValue(this.inputNames.phone, '+');
    }
    setTimeout(() => {
      callback();
    }, 0);
  }

  clearValueIfOnlyPlus = () => {
    if (this.inputManager.getValue(this.inputNames.phone).length == 1) {
      this.inputManager.setValue(this.inputNames.phone, '');
    }
  }

  setCaretAtEnd = (elem) => {
    var elemLen = elem.value.length;
    // For IE Only
    if (document.selection) {
      // Set focus
      elem.focus();
      // Use IE Ranges
      var oSel = document.selection.createRange();
      // Reset position to 0 & then set at end
      oSel.moveStart('character', -elemLen);
      oSel.moveStart('character', elemLen);
      oSel.moveEnd('character', 0);
      oSel.select();
    }
    else if (elem.selectionStart || elem.selectionStart == '0') {
      // Firefox/Chrome
      elem.selectionStart = elemLen;
      elem.selectionEnd = elemLen;
      elem.focus();
    }
  }

  // ;;render --------------------------------------------------------------------------------------

  renderMessage() {
    if (!this.state.message) return null;

    return (
      <React.Fragment>
        <Message {...this.state.message} />
        <Divider height={2} />
      </React.Fragment>
    );
  }

  renderTitle() {
    return [
      <IslandTitle key="title01">Регистрация</IslandTitle>,
      <Divider height={3} key="divider02" />
    ]
  }

  renderSubmitButton() {
    return (
      <Button disabledStyle={!this.isFormValid()} processing={this.state.processing} type="submit">
        Зарегистрироваться
      </Button>
    );
  }

  rendetInputUsername = () => {
    return (
      <Input
        handleChange={this.inputManager.handleChange}
        name={this.inputNames.username}
        placeholder="Юзернейм"
        value={this.inputManager.getValue(this.inputNames.username)}
        autoComplete='off'
      />
    )
  }

  renderInputPhoneWithHelpButton = () => {
    return (
      <InputWithButton>
        <Input
          ref={this.$phoneInput}
          handleChange={this.handleChangePhone}
          name={this.inputNames.phone}
          value={this.inputManager.getValue(this.inputNames.phone)}
          placeholder="Номер мобильного телефона"
          autoFocus={true}
          onFocus = {this.handleFocus}
          onBlur = {this.clearValueIfOnlyPlus}
          withButton
        />
        <IconButton
          absoluteUrl
          iconType="help"
          target="_blank"
          url={urls.idInfoUrl}
          insideInput
         />
      </InputWithButton>
    )
  }

  renderSwitchButtons() {
    const buttons = [
      {
        text: 'Я работодатель',
        value: 'hr',
      },
      {
        text: 'Я соискатель',
        value: 'applicant',
      },
    ];

    return (
      <div data-gtm-track="role-user">
        <SwitchButtons
          handleChange={this.inputManager.handleChange}
          handleUncheck={() => this.inputManager.setValue(this.inputNames.role, '')}
          name={this.inputNames.role}
          value={this.inputManager.getValue(this.inputNames.role)}
          buttons={buttons}
          primary
        />
      </div>
    );
  }

  renderCheckbox(name, text, link) {
    return (
      <CheckboxText
        handleChange={this.inputManager.handleChange}
        name={name}
        checked={Boolean(this.inputManager.getValue(name))}>
        {text}
        {' '}
        {this.renderLinkForCheckbox(link)}
      </CheckboxText>
    );
  }

  renderLinkForCheckbox = (link) => {
    if (link) return <a href={urls.agreementUrl} rel="noopener" target="_blank">{link}</a>

    return null;
  }

  renderInputs() {
    return (
      <InputGroup error={this.state.message?.error}>
        {this.rendetInputUsername()}
      </InputGroup>
    );
  }

  renderAcceptTermsCheckbox = () => {
    return (
      <CheckboxText
        handleChange={this.inputManager.handleChange}
        name={this.inputNames.acceptTerms}
        checked={Boolean(this.inputManager.getValue(this.inputNames.acceptTerms))}>
        Принимаю условия <a href={urls.agreementUrl} rel="noopener" target="_blank">пользовательского соглашения</a> и <a href={urls.privacyPolicyUrl} rel="noopener" target="_blank">политики конфиденциальности</a>
      </CheckboxText>
    )
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        {/* <input type="hidden" name="authenticity_token" value={this.csrfToken} /> */}
        {this.renderMessage()}
        {this.renderTitle()}
        {this.renderInputs()}
        <Divider height={3} />
        {this.renderSwitchButtons()}
        <Divider height={3} />
        {this.renderCheckbox(this.inputNames.subscription, 'Получать рассылки dev.by')}
        <Divider height={3} />
        {this.renderAcceptTermsCheckbox()}
        <Divider height={3} />
        {this.renderSubmitButton()}
      </form>
    );
  }
}

export default GoogleSignUpForm;
