import bugsnag from '@bugsnag/js';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import bugsnagReact from '@bugsnag/plugin-react';

import { railsConfig } from './config';

import './axios';

import Modernizr from '../../../../.modernizrrc';

// Services
import BrowserDetect from '../../frontend-base/js/services/browser-detect';

// Components
import ErrorPageComponent from '../../frontend-base/js/components/error-page-component/error-page-component';
import App from './app';

const bugsnagClient = bugsnag({
  apiKey: railsConfig.bugsnag_frontend_key,
  releaseStage: __DEV__ ? 'development' : 'production',
  notifyReleaseStages: ['production'],
});

/*IE Browser Detection*/
const isIE = BrowserDetect.isIE;
const isEdge = BrowserDetect.isEdge;
const isSafari = BrowserDetect.isSafari;
Modernizr.addTest('ie', () => isIE);
Modernizr.addTest('edge', () => isEdge);
Modernizr.addTest('safari', () => isSafari);

const initApp = () => {
  const mountNode = document.getElementById('root');

  const fixIE = (cb) => {
    if (!document.documentElement.classList) {
      import(/* webpackChunkName: "classlist-polyfill" */ 'classlist-polyfill')
        .then((classlistPolyfill) => {
          if (classlistPolyfill) {
            cb();
          }
        })
        .catch(error => console.log('An error occurred while loading the classlist-polyfill library', error));
    } else {
      cb();
    }
  };

  bugsnagClient.use(bugsnagReact, React);
  const ErrorBoundary = bugsnagClient.getPlugin('react');

  const renderApp = () => {
    render(
      <ErrorBoundary
        FallbackComponent={() => (
          <ErrorPageComponent staticPath={railsConfig.lenta_static_assets_path} />
        )}>
        <BrowserRouter basename="/@">
          <App />
        </BrowserRouter>
      </ErrorBoundary>,
      mountNode
    );
  };

  fixIE(renderApp);
};

initApp();
