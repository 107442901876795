import React from 'react';
import PropTypes from 'prop-types';

class FadeInAnimation extends React.Component {
  static propTypes = {};

  render() {
    return (
      <div className="fade-in-animation">
        {this.props.children}
      </div>
    );
  }
}

export default FadeInAnimation;
