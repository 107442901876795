import React from 'react';
import PropTypes from 'prop-types';

// Config
import {
  metrics, railsConfig
} from '../../config';

// Components
import Header from '../../../../frontend-base/js/components/header/header';
import Main from '../main/main';

class Layout extends React.Component {
  static propTypes = {};

  rootUrl = `//${railsConfig.host}`;

  // ;;render --------------------------------------------------------------------------------------

  render() {
    return (
      <div className="layout">
        <Header
          absoluteLinks
          minimal
          rootUrl={this.rootUrl}
          transitionDuration={metrics.transitionDurationDefault}
        />
        <Main />
      </div>
    );
  }
}

export default Layout;
