let documentElement;

if (typeof document !== 'undefined') documentElement = document.documentElement;

class DOMClassName {

  add(classNames) {
    if (!classNames) return;
    if (typeof classNames === 'string') {
      document.documentElement.classList.add(classNames);
    } else {
      document.documentElement.classList.add(...classNames);
    }
  }

  remove(classNames) {
    if (!classNames) return;
    if (typeof classNames === 'string') {
      document.documentElement.classList.remove(classNames);
    } else {
      document.documentElement.classList.remove(...classNames);
    }
  }
}

export default new DOMClassName();
