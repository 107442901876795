import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

class HeaderNav extends React.Component {
  static propTypes = {
    /**
     * Handler for click on search item
     */
    handleClickSearch: PropTypes.func,

    /**
     * Menu structure
     */
    menuStructure: PropTypes.array,

    /**
     * True if HeaderNav instance inside popup
     */
    isPopup: PropTypes.bool,

    /**
     * Header Search display state
     */
    showSearch: PropTypes.bool,

    /**
     * Vacancy count
     */
    vacancyCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  };

  labelRef = React.createRef();

  componentDidMount() {
    this.setCountForLabel(document.head.querySelector('[name=vacancies-count]')?.content);
  }

  // ;;inner ---------------------------------------------------------------------------------------

  handleClickSearch = (e) => {
    e.preventDefault();

    this.props.handleClickSearch();
    window.scrollTo(0, 0);
  };

  setCountForLabel(value) {
    try {
      if (value) {
        this.labelRef.current.innerHTML = value;
      }
    } catch (e) {
      console.error(e)
    }
  }

  // ;;render --------------------------------------------------------------------------------------
  renderLabel(label) {
    if (!label) return null;

    if(label == 'vacancyCount') {
      return <span className="header-nav__label" ref={this.labelRef} dangerouslySetInnerHTML={{ __html: this.props?.vacancyCount }}/>
    }

    return <span className="header-nav__label" dangerouslySetInnerHTML={{ __html: label }} />;
  }

  renderItem(item) {
    if (item.link) {
      const Tag = item.inner ? NavLink : 'a';
      const tagProps = item.inner
        ? {
          to: item.link,
          activeClassName: 'header-nav__link_current',
        }
        : {
          href: item.link,
        };

      return (
        <Tag {...tagProps} className="header-nav__link">
          {item.title}
          {this.renderLabel(item.label)}
        </Tag>
      );
    }

    return (
      <button className="header-nav__link" onClick={item.handleClick}>
        {item.title}
      </button>
    );
  }

  renderItems() {
    const menuList = this.props.menuStructure.slice(0);

    if (this.props.isPopup) {
      menuList.push({
        title: 'Поиск',
        handleClick: this.handleClickSearch,
      });
    }

    return menuList.map(item => (
      <div key={item.title} className="header-nav__item">
        {this.renderItem(item)}
      </div>
    ));
  }

  render() {
    return (
      <nav
        className={classNames({
          'header-nav': true,
          'header-nav_popup': this.props.isPopup,
          'header-nav_show-search': this.props.showSearch,
        })}>
        <div className="header-nav__content">{this.renderItems()}</div>
      </nav>
    );
  }
}

export default HeaderNav;
