import React from 'react';

class ButtonWithIcon extends React.Component {

  render() {
    return (
      <div className="button-with-icon">
        {this.props.children[0]}
        <div className="button-with-icon__icon">
          {this.props.children[1]}
        </div>
      </div>
    );
  }
}

export default ButtonWithIcon;
