import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Icon from '../icon/icon';

class SwitchButtons extends React.Component {
  static propTypes = {

    /**
     * Array data of buttons
     */
    buttons: PropTypes.arrayOf(PropTypes.shape({

      /**
       * Text for button
       */
      text: PropTypes.string,

      /**
       * Value of button
       */
      value: PropTypes.string,
    })),

    /**
     * Handler for onChange
     */
    handleChange: PropTypes.func,

    /**
     * Name of radio group
     */
    name: PropTypes.string,

    /**
     * Current value of radio group
     */
    value: PropTypes.string,
  };

  // ;;compute -------------------------------------------------------------------------------------

  isChecked(value) {
    return value === this.props.value;
  }

  // ;;render --------------------------------------------------------------------------------------

  renderButtons() {
    return this.props.buttons.map(data => this.renderButton(data));
  }

  renderUncheckButton() {
    if (!this.props.handleUncheck) return null

    return (
      <span
        className="switch-buttons__button-uncheck"
      >
        <Icon iconType="close" size={16} />
      </span>
    )
  }

  renderButton(data) {
    return (
      <label className="switch-buttons__label" key={data.value}>
        <input
          checked={this.isChecked(data.value)}
          className="switch-buttons__input"
          name={this.props.name}
          onChange={this.props.handleChange}
          onClick={this.props.handleUncheck}
          type="radio"
          value={data.value}
        />
        <div className={classNames({
          'switch-buttons__button': true,
          'switch-buttons__button_primary': this.props.primary,
          'switch-buttons__button_uncheck': !!this.props.handleUncheck
        })}>
          <span className="switch-buttons__button-text">
            {data.text}
          </span>
        </div>
        {this.renderUncheckButton()}
      </label>
    );
  }

  render() {
    return (
      <div className="switch-buttons">
        {this.renderButtons()}
      </div>
    );
  }
}

export default SwitchButtons;
