import React from 'react';
import PropTypes from 'prop-types';

const icons = {
  plusIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M15 7h-6v-6c0-.6-.4-1-1-1s-1 .4-1 1v6h-6c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1v-6h6c.6 0 1-.4 1-1s-.4-1-1-1z"
          fill="currentColor"
          />
        </svg>
    );
  },
  aliasIcon() {
    return (
      <svg width="16" height="16">
        <path
          d="M5.5 3c-.3 0-.5.2-.5.5s.2.5.5.5h5.7l-7.6 7.6c-.2.2-.2.6 0 .8.2.2.6.2.8 0L12 4.8v5.7c0 .3.2.5.5.5s.5-.2.5-.5V3H5.5z"
          fill="currentColor"
        />
      </svg>
    );
  },
  arrowUpIcon(props) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.707 17.707c-.391.391-1.024.391-1.414 0-.391-.391-.391-1.024 0-1.414l10.707-10.707 10.707 10.707c.391.391.391 1.024 0 1.414-.391.391-1.024.391-1.414 0l-9.293-9.293-9.293 9.293z"
          fill="currentColor"
        />
      </svg>
    );
  },
  arrowPrevIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M22.6 11h-18.7l5.3-5.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-7.8 7.7 7.8 7.8c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-5.4-5.4h18.8c.6 0 1-.4 1-1s-.4-1-1-1z"
          fill="currentColor"
        />
      </svg>
    );
  },
  careerIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M22 4h-4v-2c0-1.1-.9-2-2-2h-8c-1.1 0-2 .9-2 2v2h-4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-14-2h8v2h-8v-2zm14 16h-20v-12h20v12zm-17-2h14c.6 0 1-.4 1-1s-.4-1-1-1h-14c-.6 0-1 .4-1 1s.4 1 1 1z"
          fill="currentColor"
        />
      </svg>
    );
  },
  careerDoneIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M22 4h-4v-2c0-1.1-.9-2-2-2h-8c-1.1 0-2 .9-2 2v2h-4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-6 0h-8v-2h8v2z"
          fill="currentColor"
        />
      </svg>
    );
  },
  checkIcon(props) {
    let fill = "currentColor";
    if (props.fill) fill = props.fill;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M21.7 5.3c-.4-.4-1-.4-1.4 0l-11.3 11.3-4.3-4.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l5 5c.4.4 1 .4 1.4 0l12-12c.4-.4.4-1 0-1.4z"
          fill={fill}
        />
      </svg>
    );
  },
  check16Icon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M14.1 2.9c-.4-.4-1-.4-1.4 0l-7.2 7-2.3-2.2c-.4-.4-1-.4-1.4 0-.3.3-.3.9.1 1.3l3.6 3.5 8.6-8.3c.4-.3.4-1 0-1.3z"
          fill={props.fill}
        />
      </svg>
    );
  },
  chevronIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M13.4 5.6c-.2-.2-.6-.2-.8 0l-4.6 4.6-4.6-4.6c-.2-.2-.6-.2-.8 0-.2.2-.2.6 0 .8l5.4 5.4 5.4-5.4c.2-.2.2-.6 0-.8z"
          fill="currentColor"
        />
      </svg>
    );
  },
  closeIcon(props) {
    return props.size === 16 ? (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M8.9 8.1l3.8-3.8c.2-.2.2-.6 0-.8-.2-.2-.6-.2-.8 0l-3.8 3.7-3.9-3.8c-.2-.2-.6-.2-.8 0-.2.2-.2.6 0 .8l3.8 3.8-3.8 3.8c-.2.2-.2.6 0 .8.2.2.6.2.8 0l3.8-3.8 3.8 3.8c.2.2.6.2.8 0 .2-.2.2-.6 0-.8l-3.7-3.7z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M13.4 12l7.3-7.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-7.3 7.3-7.3-7.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l7.3 7.3-7.3 7.3c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l7.3-7.3 7.3 7.3c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-7.3-7.3z"
          fill="currentColor"
        />
      </svg>
    );
  },
  commentsIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
        <path d="M6 0l-5 13h1.5l5-13h-1.5zm5.5 0l-5 13h1.5l5-13h-1.5z" fill="currentColor" />
      </svg>
    );
  },
  docIcon(props) {
    return (
      <svg width="24" height="24">
        <path
          d="M14.2 1H4.5C3.7 1 3 1.7 3 2.5v19c0 .8.7 1.5 1.5 1.5h15c.8 0 1.5-.7 1.5-1.5V7.6L14.2 1zM14 3.5L18.6 8h-4.1c-.3 0-.5-.2-.5-.5v-4zM19 21H5V3h7v4.5c0 1.4 1.1 2.5 2.5 2.5H19v11z"
          fill="currentColor"
        />
      </svg>
    );
  },
  errorIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="160" height="200" viewBox="0 0 160 200">
        <path
          d="M60 40h-20v40h20v-40zm80 0v-20h-20v20h20zm0 0v20h-40v-40h20v-20h-120v200h160v-160h-20zm0 140h-120v-160h60v60h60v100zm-40-20h20v-20h-20v20zm-40-40v20h40v-20h-40zm-20 40h20v-20h-20v20z"
          fill="#FFF"
        />
      </svg>
    );
  },
  eyeCloseIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M23.3 10.4c-.3-.4-1-.5-1.4-.2-3 2.2-6.3 3.3-9.9 3.3s-6.9-1.1-9.9-3.3c-.5-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4c3.3 2.5 7 3.7 11.1 3.7 4 0 7.8-1.2 11.1-3.7.4-.3.5-.9.2-1.4z"
          fill="currentColor"
        />
      </svg>
    );
  },
  eyeOpenIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M23.1 10.7c-3.3-2.5-7.1-3.7-11.1-3.7s-7.8 1.2-11.1 3.7c-.4.3-.5 1-.2 1.4s1 .5 1.4.2c1.6-1.2 3.3-2 5.1-2.6-.1.4-.2.9-.2 1.3 0 2.8 2.2 5 5 5s5-2.2 5-5c0-.4-.1-.9-.2-1.3 1.8.5 3.5 1.4 5.1 2.6.4.3 1.1.2 1.4-.2s.2-1.1-.2-1.4zm-11.1 3.3c-1.7 0-3-1.3-3-3 0-.7.2-1.3.6-1.8.8-.1 1.6-.2 2.4-.2s1.6.1 2.4.2c.4.5.6 1.1.6 1.8 0 1.7-1.3 3-3 3z"
          fill="currentColor"
        />
      </svg>
    );
  },
  editIcon(props) {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g id="icons/edit-16" fill="none" fillRule="evenodd">
          <path
            d="M13,7.5 C13,7.22385763 13.2238576,7 13.5,7 C13.7761424,7 14,7.22385763 14,7.5 L14,13 C14,13.5522847 13.5522847,14 13,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3 C2,2.44771525 2.44771525,2 3,2 L8.5,2 C8.77614237,2 9,2.22385763 9,2.5 C9,2.77614237 8.77614237,3 8.5,3 L3,3 L3,13 L13,13 L13,7.5 Z M13.1464466,2.14644661 C13.3417088,1.95118446 13.6582912,1.95118446 13.8535534,2.14644661 C14.0488155,2.34170876 14.0488155,2.65829124 13.8535534,2.85355339 L7.47989616,9.22721062 C7.3497214,9.35738538 6.78068581,9.69071871 5.77278938,10.2272106 C6.30928129,9.21931419 6.64261462,8.6502786 6.77278938,8.52010384 L13.1464466,2.14644661 Z"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  },
  googleIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <path
            id="path-1"
            d="M22.744186,10 L12.255814,10 L12.255814,14.25 L18.2930233,14.25 C17.7302326,16.95 15.3767442,18.5 12.255814,18.5 C8.57209302,18.5 5.60465116,15.6 5.60465116,12 C5.60465116,8.4 8.57209302,5.5 12.255814,5.5 C13.8418605,5.5 15.2744186,6.05 16.4,6.95 L19.6744186,3.75 C17.6790698,2.05 15.1209302,1 12.255814,1 C6.01395349,1 1,5.9 1,12 C1,18.1 6.01395349,23 12.255814,23 C17.8837209,23 23,19 23,12 C23,11.35 22.8976744,10.65 22.744186,10 Z"
          />
        </defs>
        <g id="icons/google-24" fill="none" fillRule="evenodd">
          <mask id="mask-2" fill="#fff">
            <use xlinkHref="#path-1" />
          </mask>
          <polygon
            fill="#FBBC05"
            mask="url(#mask-2)"
            points="-0.023255814 18.5 -0.023255814 5.5 8.6744186 12"
          />
          <polygon
            fill="#EA4335"
            mask="url(#mask-2)"
            points="-0.023255814 5.5 8.6744186 12 24 6.9999345 24 0 -0.023255814 0"
          />
          <polygon
            fill="#34A853"
            mask="url(#mask-2)"
            points="-0.023255814 18.5 12.9729663 8.76267902 24 8.95959473 24 24 -0.023255814 24"
          />
          <polygon
            fill="#4285F4"
            mask="url(#mask-2)"
            points="24.5348837 24 8.6744186 12 6.62790698 10.5 24.5348837 5.5"
          />
        </g>
      </svg>
    );
  },
  helpIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="12" fill="rgba(34,34,34,0.1)" />
        <path
          d="M12.2 5c2.5 0 3.9 1.4 3.9 3.4 0 1.7-.9 2.4-2.1 3.3-.8.6-1.1.9-1.1 1.8v.5h-2v-.5c0-1.4.5-2 1.8-3 .8-.6 1.4-1.1 1.4-2.1 0-.9-.7-1.7-1.9-1.7s-2 .6-2.1 2h-2c.1-2.2 1.6-3.7 4.1-3.7zm-.2 13.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    );
  },
  importantIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="8" fill="#FF3344" />
        <circle cx="12" cy="16.1" r="1.2" fill="#FFFFFF" />
        <path
          d="M12 6.1c-.7 0-1.2.6-1.2 1.2v1.7c0 .6.1 1.2.2 1.7l.6 2.5c.1.2.3.4.5.4s.5-.2.5-.4l.6-2.5c.1-.6.2-1.2.2-1.7v-1.6c-.2-.7-.7-1.3-1.4-1.3z"
          fill="#FFFFFF"
        />
      </svg>
    );
  },
  alertIcon(props) {
    const width = props.width || 20;
    const height = props.height || 20;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
        <path
          d="M10 0c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 18.5c-4.7 0-8.5-3.8-8.5-8.5s3.8-8.5 8.5-8.5 8.5 3.8 8.5 8.5-3.8 8.5-8.5 8.5zm0-5.2c-.5 0-.8.4-.8.8 0 .5.4.8.8.8s.8-.4.8-.8-.3-.8-.8-.8zm.2-8.3h-.5c-.6 0-1 .5-.9 1.1l.5 5.6h1.3l.5-5.6v-.1c.1-.6-.4-1-.9-1z"
          fill="currentColor"
        />
      </svg>
    );
  },
  logoIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="104" height="40" viewBox="0 0 104 40">
        <path
          d="M87.5 12.4c-4.4-2.2-6.9.6-7.9 2.2-2.6 4.9-6.1 10.4-6.1 10.4h-.1s-3.7-8.1-4.6-9.7c-1.6-2.7-4-2.9-5.8-2.5-3.3.8-4 4.1-4.1 4.7-2.2 4.5-8.1 12.1-13.9 12.1v-.1c2.1-1.5 6-5.6 6-10.5 0-4.8-3.3-7.9-8-7.9-8.7 0-10.2 10.5-10.2 12.5-4.3 4.3-10.4 6.8-15.7 6.8-3.2 0-4.9-1.8-4.9-3.8 0-3.5 3.7-4.3 5.8-3.6.6.4 2.6 1.8 5.1 1.8 2.5 0 5.4-1.8 5.4-4.7 0 0 .1-13.7.1-15.5 0-2.6-2.6-4.6-5.8-4.6-3.1 0-5.5 2-5.5 4.7l.2 9.8c-4.9-1.4-17.5-.1-17.5 11.2 0 9.9 9.5 14.3 18.1 14.3 9.1 0 17.1-4 18.6-4.8 1 1.2 4.4 4.1 9.9 4.1 9.2 0 16.3-8.3 17.9-10.4h.1s3.1 5.6 3.8 6.8c1.3 2.2 3.3 3.4 5.4 3.4 2.2 0 4.3-1.1 5.5-2.9 0 0 7.8-11.6 10.3-16.4 1.7-2.8.8-6-2.1-7.4m8.9 11.5c-4.2 0-7.5 3.3-7.5 7.5 0 4.1 3.3 7.4 7.5 7.4s7.5-3.3 7.5-7.4c0-4.2-3.3-7.5-7.5-7.5"
          fill="currentColor"
        />
      </svg>
    );
  },
  menuIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M23 18h-22c-.6 0-1 .4-1 1s.4 1 1 1h22c.6 0 1-.4 1-1s-.4-1-1-1zm-22-12h22c.6 0 1-.4 1-1s-.4-1-1-1h-22c-.6 0-1 .4-1 1s.4 1 1 1zm22 5h-22c-.6 0-1 .4-1 1s.4 1 1 1h22c.6 0 1-.4 1-1s-.4-1-1-1z"
          fill="currentColor"
        />
      </svg>
    );
  },
  moreIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M3 7c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm10 0c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm-5 0c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1z"
          fill="currentColor"
        />
      </svg>
    );
  },
  photoIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="24" fill="rgba(0, 0, 0, 0.8)" />
        <path
          d="M18.1 17l1-1.6c.5-.9 1.5-1.4 2.6-1.4h4.7c1 0 2 .5 2.6 1.4l1 1.6h5c.6 0 1 .4 1 1v13c0 .6-.4 1-1 1h-22c-.6 0-1-.4-1-1v-13c0-.6.4-1 1-1h5.1zm5.9 13c3.3 0 6-2.7 6-6s-2.7-6-6-6-6 2.7-6 6 2.7 6 6 6z"
          fill="#FFF"
        />
      </svg>
    );
  },
  photoVideoIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="24" fill="rgba(0, 0, 0, 0.8)" />
        <path
          d="M22 16c.9 0 1.7.2 2.4.5l1 .5 8.6 4.3v-5.3c0-.6-.4-1-1-1h-5.1l-1-1.6c-.5-.9-1.5-1.4-2.6-1.4h-4.7c-1 0-2 .5-2.6 1.4l-1 1.6h-5c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h10v-2.1c-2.8-.5-5-2.9-5-5.9 0-3.3 2.7-6 6-6zm19.5 11.3l-17.1-8.6c-.1-.1-.3-.1-.4-.1-.5 0-.9.4-.9.9v17.1c0 .1 0 .3.1.4.2.5.8.7 1.3.4l17.1-8.6c.2-.1.3-.2.4-.4.1-.4-.1-.9-.5-1.1z"
          fill="#FFF"
        />
      </svg>
    );
  },
  requiredIcon(props) {
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16">
        <polygon
          points="11.9,7.3 11.5,6.1 8.6,7.1 8.7,4 7.4,4 7.5,7.1 4.5,6.1 4.2,7.3 7.1,8.1 5.2,10.6 6.3,11.4 8,8.8 9.8,11.4 10.8,10.6 8.9,8.1"
          fill="currentColor"
        />
      </svg>
    );
  },
  searchIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M21.7 20.3l-3.7-3.7c1.2-1.5 2-3.5 2-5.6 0-5-4-9-9-9s-9 4-9 9 4 9 9 9c2.1 0 4.1-.7 5.6-2l3.7 3.7c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4zm-10.7-2.3c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z"
          fill="currentColor"
        />
      </svg>
    );
  },
  skdoLogoIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="53" height="16" viewBox="0 0 53 16">
        <path
          d="M11.8 7.1c-.5-.1-1.9-.4-1.9-.4-1.1-.2-1.8-.4-1.8-1 0-.5.4-.9 1.6-.9 1 0 1.7.3 1.8 1.3h3.4c-.1-1.8-1.5-3-5.1-3-2.9-.1-5.2.8-5.2 3 0 1.7 1.7 2.2 3.4 2.6.5.1 1.9.4 1.9.4 1.1.2 1.8.5 1.8 1.1 0 .5-.4 1-1.9 1-1 0-1.8-.4-1.8-1.5h-3.7c0 1.6.8 3.3 5.4 3.3 3 0 5.6-.6 5.6-3.1-.1-1.6-1.2-2.3-3.5-2.8zm11.3-3.9h-4.2l-3.9 4.5 4 5h4.6l-4.4-5.2 3.9-4.3zm19.8-.2c-4.1 0-6.3 1.9-6.3 5 0 3 1.7 5 6.3 5 4 0 6.3-1.9 6.3-5 0-3-1.8-5-6.3-5zm0 8c-2 0-2.6-1.3-2.6-3s.8-3 2.6-3c1.9 0 2.6 1.3 2.6 3 .1 1.7-.6 3-2.6 3zm9.4-8.4c-.4-.8-1.1-1.5-1.9-1.9-.8-.5-1.6-.7-3.8-.7h-40.2c-2.2 0-3 .2-3.9.7-.8.4-1.4 1-1.8 1.9-.5.8-.7 1.6-.7 3.8v3.2c0 2.2.2 3 .7 3.9.4.8 1.1 1.5 1.9 1.9.8.4 1.6.6 3.8.6h40.2c2.2 0 3-.2 3.9-.7.8-.4 1.5-1.1 1.9-1.9.4-.8.7-1.6.7-3.9v-3.1c-.1-2.2-.3-3-.8-3.8zm.2 7c0 2-.2 2.8-.6 3.6-.4.7-1 1.3-1.7 1.7-.8.5-1.7.6-3.6.6h-40.2c-2 0-2.8-.2-3.6-.6-.7-.4-1.3-1-1.7-1.7-.5-.8-.6-1.7-.6-3.6v-3.2c0-2 .2-2.8.6-3.6.4-.7 1-1.3 1.7-1.7.8-.5 1.7-.6 3.6-.6h40.2c2 0 2.8.2 3.6.6.7.4 1.3 1 1.7 1.7.5.8.6 1.7.6 3.6v3.2zm-22.7-6.4h-5.6v9.5h5.5c3.8 0 6.4-1.2 6.4-4.8 0-3.7-2.9-4.7-6.3-4.7zm-.8 7.6h-1.2v-5.6h1.5c1.9 0 3.2.6 3.2 2.8 0 2.4-1.4 2.8-3.5 2.8z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialFbIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M20 9h2v-4c-.3.1-1.5 0-3 0-2.8 0-4.7 1.8-5 5v3h-3v4h3v10h4v-10h3l1-4h-4v-2c.1-1.6.4-2.3 2-2z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialGhIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M26.4 10c-1.1-1.8-2.5-3.3-4.4-4.4-1.8-1.1-3.8-1.6-6-1.6s-4.2.5-6 1.6c-1.9 1.1-3.3 2.5-4.4 4.4-1.1 1.8-1.6 3.8-1.6 6 0 2.6.8 5 2.3 7.1 1.5 2.1 3.5 3.5 5.9 4.3.3.1.5 0 .6-.1.2-.1.2-.3.2-.5v-2.2l-.4.1c-.2 0-.5.1-.9.1-.3 0-.7 0-1.1-.1-.4-.1-.7-.2-1-.5-.3-.2-.6-.6-.7-1l-.2-.4c-.1-.2-.3-.5-.5-.8-.2-.3-.5-.5-.7-.6l-.1-.1-.2-.2c-.1-.1-.1-.1-.1-.2s0-.1.1-.2c.1 0 .2-.1.5-.1h.3c.2 0 .5.2.8.4.3.2.6.5.8.8.2.4.5.8.9 1 .3.2.7.3 1 .3s.6 0 .9-.1l.7-.2c.1-.7.3-1.2.8-1.6-.6-.1-1.1-.2-1.6-.3-.5-.1-1-.3-1.5-.6s-.9-.6-1.3-1c-.3-.4-.6-1-.8-1.6-.2-.7-.3-1.5-.3-2.3 0-1.3.4-2.3 1.2-3.2-.4-.9-.3-2 .1-3.2.3-.1.8 0 1.3.2.6.2 1 .4 1.3.6s.5.3.7.4c1-.3 2-.4 3-.4s2 .1 3 .4l.6-.4c.4-.2.9-.5 1.4-.7.6-.2 1-.3 1.3-.2.5 1.2.5 2.2.1 3.2.8.9 1.2 2 1.2 3.2 0 .9-.1 1.7-.3 2.4-.2.7-.5 1.2-.8 1.6-.3.4-.8.8-1.3 1-.5.3-1 .5-1.5.6-.5.1-1 .2-1.6.3.5.5.8 1.2.8 2.2v3.3c0 .2.1.3.2.5.1.1.3.2.6.1 2.4-.8 4.4-2.2 5.9-4.3 1.6-2 2.4-4.4 2.4-7 0-2.2-.5-4.2-1.6-6z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialGoIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M25.8 14h-9.3v4h5.2c-.5 2.5-2.7 3.9-5.5 3.9-3.3 0-6-2.6-6-5.9 0-3.3 2.7-5.9 6-5.9 1.4 0 2.7.5 3.8 1.3l3-2.9c-1.8-1.5-4.2-2.5-6.8-2.5-5.6 0-10.2 4.5-10.2 10s4.6 10 10.2 10c5.1 0 9.8-3.6 9.8-10 0-.6-.1-1.4-.2-2z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialIgIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M27.9 11.1c-.1-1.3-.3-2.1-.6-2.9-.2-.8-.6-1.5-1.3-2.2s-1.3-1.1-2.1-1.4c-.8-.3-1.6-.5-2.9-.6h-5s-3.7 0-4.9.1c-1.3 0-2.2.2-3 .5-.7.3-1.4.7-2.1 1.4s-1.1 1.4-1.4 2.1c-.3.8-.5 1.6-.6 2.9v5s0 3.7.1 4.9c.1 1.3.3 2.1.6 2.9.2.8.6 1.5 1.3 2.2s1.3 1.1 2.1 1.4c.8.3 1.6.5 2.9.6h5s3.7 0 4.9-.1c1.3-.1 2.1-.3 2.9-.6.8-.3 1.5-.7 2.1-1.4s1.1-1.3 1.4-2.1c.3-.8.5-1.6.6-2.9.1-1.2.1-1.6.1-4.9s0-3.7-.1-4.9zm-2.1 9.7c-.1 1.2-.2 1.8-.4 2.2-.2.6-.5 1-.9 1.4-.4.4-.8.7-1.4.9-.4.2-1.1.4-2.2.4-1.3.1-1.6.1-4.8.1s-3.6 0-4.8-.1c-1.2-.1-1.8-.2-2.2-.4-.6-.2-1-.5-1.4-.9-.4-.4-.7-.8-.9-1.4-.2-.4-.4-1.1-.4-2.2-.1-1.3-.1-1.6-.1-4.8s0-3.6.1-4.8c-.1-1.2.1-1.9.2-2.3.2-.6.5-1 .9-1.4.5-.4.9-.6 1.4-.9.4-.2 1.1-.4 2.2-.4 1.3-.1 1.6-.1 4.8-.1 3.2 0 3.6 0 4.8.1 1.2.1 1.8.2 2.2.4.6.2 1 .5 1.4.9.4.4.7.8.9 1.4.2.4.4 1.1.4 2.2.1 1.3.1 1.6.1 4.8s.1 3.7.1 4.9zm-9.8-11c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2 6.2-2.8 6.2-6.2-2.8-6.2-6.2-6.2zm0 10.2c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm6.4-11.8c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4c.8 0 1.4-.6 1.4-1.4s-.6-1.4-1.4-1.4z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialInIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M7 24h4.2v-12.5h-4.2v12.5zm2.1-19c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm11.9 6c-2 0-3.4 1.1-3.9 2.2h-.1v-1.7h-4v12.5h4.2v-5.9c0-1.7.3-3.4 2.5-3.4 2.1 0 2.2 2 2.2 3.5v5.8h4.1v-6.6c0-3.6-.8-6.4-5-6.4z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialRssIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M7 21v4h4c0-2.2-1.8-4-4-4zm0-16v4c8.8 0 16 7.2 16 16h4c0-11-9-20-20-20zm0 8v4c4.4 0 8 3.6 8 8h4c0-6.6-5.4-12-12-12z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialTgIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M25.2 5.2l-21.3 8.5s-1 .3-.9 1c.1.6.9.9.9.9l5.4 1.8 12.9-8.3s.7-.5.7 0c0 0 .1.1-.3.5s-10.1 9.2-10.1 9.2v.1l-.7 5.9c.3.1.6-.1.6-.1l3.5-3.2 5.4 4.2c1.5.6 2-.7 2-.7l3.7-19.3c0-1.2-1.8-.5-1.8-.5z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialTwIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M29 9.2c-.9.4-1.8.6-2.8.7 1-.6 1.8-1.5 2.2-2.6-1 .5-2 .9-3.1 1.1-1-.8-2.3-1.4-3.7-1.4-2.7 0-4.9 2.1-4.9 4.7 0 .4 0 .7.1 1.1-4.1-.2-7.7-2.1-10.1-4.9-.5.6-.7 1.5-.7 2.3 0 1.6.9 3.1 2.2 3.9-.8 0-1.6-.2-2.2-.6v.1c0 2.3 1.7 4.2 3.9 4.6-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 1.9 2.4 3.2 4.6 3.3-1.7 1.3-3.8 2-6.1 2-.4 0-.8 0-1.2-.1 2.2 1.3 4.8 2.1 7.5 2.1 9.1 0 14-7.1 14-13.3v-.6c1-.7 1.8-1.6 2.5-2.5z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialVkIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M27.6 21.8c-.3-.8-1.2-1.9-2.4-3.2l-1.9-2c-.4-.5-.3-.8 0-1.2 0 0 3.4-4.7 3.8-6.4.2-.6 0-1-.8-1h-2.8c-.7 0-1 .4-1.2.8 0 0-1.4 3.4-3.4 5.7-.7.6-.9.8-1.3.8-.2 0-.4-.2-.4-.8v-5.5c0-.7-.2-1-.8-1h-4.4c-.4 0-.7.3-.7.6 0 .7 1 .8 1.1 2.7v4.1c0 .9-.2 1.1-.5 1.1-.9 0-3.3-3.4-4.6-7.4-.6-.8-.8-1.1-1.5-1.1h-2.8c-.8 0-1 .4-1 .8 0 .7.9 4.4 4.4 9.2 2.3 3.2 5.6 5 8.6 5 1.8 0 2-.4 2-1.1v-2.5c0-.8.2-.9.7-.9.4 0 1.1.2 2.8 1.8 1.9 1.9 2.2 2.7 3.3 2.7h2.8c.8 0 1.2-.4 1-1.2z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialYzIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M28,15.5c0-4.6,0-8.1-1.7-9.8C24.6,4,21.3,4,16.5,4c0.2,5.1,0.3,7.5,2.1,9.4C20.5,15.2,22.9,15.3,28,15.5z M4,16.5
          c0,4.6,0,8.1,1.7,9.8c1.7,1.7,5,1.7,9.8,1.7c-0.2-5.1-0.3-7.5-2.1-9.4C11.5,16.8,9.1,16.7,4,16.5z M16.5,28c4.8,0,8.1,0,9.8-1.7
          c1.7-1.7,1.7-5.2,1.7-9.8c-5.1,0.2-7.5,0.3-9.4,2.1C16.8,20.5,16.7,22.9,16.5,28z M4,15.5c5.1-0.2,7.5-0.3,9.4-2.1
          c1.8-1.9,2-4.2,2.1-9.4c-4.8,0-8.1,0-9.8,1.7C4,7.4,4,10.9,4,15.5z"
          fill="currentColor"
        />
      </svg>
    );
  },
  socialYtIcon(props) {
    const width = props.width || 32;
    const height = props.height || 32;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
        <path
          d="M27.5 10.2c-.3-1-1.1-1.9-2.1-2.1-1.9-.6-9.4-.6-9.4-.6s-7.5 0-9.4.5c-1 .3-1.8 1.1-2.1 2.2-.5 1.9-.5 5.8-.5 5.8s0 4 .5 5.8c.3 1 1.1 1.9 2.1 2.1 1.9.5 9.4.5 9.4.5s7.5 0 9.4-.5c1-.3 1.8-1.1 2.1-2.1.5-1.8.5-5.8.5-5.8s0-3.9-.5-5.8zm-14.5 9.8v-8l8 4-8 4z"
          fill="currentColor"
        />
      </svg>
    );
  },
  userIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M12 15c3.9 0 7-3.1 7-7s-3.1-7-7-7-7 3.1-7 7 3.1 7 7 7zm0-12c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5zm6.1 14.7c-1.9-.5-4.1-.7-6.1-.7s-4.2.2-6.1.7c-3 .7-4.9 2.1-4.9 4.3 0 .6.4 1 1 1s1-.4 1-1c0-2 4.2-3 9-3s9 1 9 3c0 .6.4 1 1 1s1-.4 1-1c0-2.2-1.9-3.6-4.9-4.3z"
          fill="currentColor"
        />
      </svg>
    );
  },
  videoIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="24" fill="rgba(0, 0, 0, 0.8)" />
        <path
          d="M17.6 13.1l19.8 9.9c.5.3.8.9.5 1.5-.1.2-.3.4-.5.5l-19.8 9.9c-.5.3-1.2.1-1.5-.5-.1-.2-.1-.3-.1-.5v-19.8c0-.6.5-1.1 1.1-1.1.2 0 .3 0 .5.1z"
          fill="#FFF"
        />
      </svg>
    );
  },
  voteIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M8 4l-8 8h16z" fill="currentColor" />
      </svg>
    );
  },
  trashIcon(props) {
    const variants = {
      16: (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path
            d="M12.6 5c-.3 0-.6.2-.6.4l-.6 6.4c-.1.6-.5 1.1-1.1 1.1h-4.6c-.5 0-1-.5-1.1-1.1l-.6-6.4c0-.2-.3-.4-.6-.4-.2 0-.4.3-.4.6l.6 6.4c.1 1.1 1 2 2.1 2h4.5c1.1 0 2-.9 2.1-2l.7-6.4c0-.3-.2-.6-.4-.6zm.8-2h-4.4c0-.6-.4-1-1-1s-1 .4-1 1h-4.4c-.3 0-.6.2-.6.5s.3.5.6.5h10.8c.3 0 .6-.2.6-.5s-.3-.5-.6-.5z"
            fill="currentColor"
          />
        </svg>
      ),
      24: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19.1 8h-.1c-.5 0-1 .4-1 .9l-.7 10.1c0 .5-.5.9-1 .9h-8.6c-.5 0-1-.4-1-.9l-.6-10.1c-.1-.5-.5-.9-1-.9h-.1c-.5 0-.9.4-.9.9v.1l.8 10.7c0 1.3 1.1 2.3 2.4 2.3h9.3c1.3 0 2.4-1 2.5-2.3l.8-10.7c.1-.5-.3-1-.8-1zm.9-4h-6c0-1.1-.9-2-2-2s-2 .9-2 2h-6c-.6 0-1 .4-1 1s.4 1 1 1h16c.5 0 1-.4 1-1s-.4-1-1-1z"
            fill="currentColor"
          />
        </svg>
      ),
    };

    return variants[props.size] || variants['24'];
  },
  tgBotIcon(props) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 5a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v1h1a3 3 0 0 1 3 3v6a1 1 0 1 1-2 0V9a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v6a1 1 0 1 1-2 0V9a3 3 0 0 1 3-3h1V5Zm10 0v1H8V5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1Zm5.8 13.4a1 1 0 0 0-1.4-.2l-.07.04c-.06.05-.17.11-.32.2-.3.15-.78.38-1.47.61-1.38.47-3.57.95-6.75.95-3.19 0-5.27-.48-6.52-.94a7.46 7.46 0 0 1-1.6-.81h-.01a1 1 0 0 0-1.33 1.5l.44-.5-.44.5h.01l.01.01a2.35 2.35 0 0 0 .16.13l.42.28c.37.22.9.5 1.66.77a21.1 21.1 0 0 0 7.2 1.06c3.38 0 5.8-.52 7.39-1.05a11.72 11.72 0 0 0 2.36-1.1l.04-.04h.01l.01-.01s-1-1.33 0 0a1 1 0 0 0 .2-1.4ZM10 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm5.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
          fill="currentColor"
        />
      </svg>
    )
  },
  currencyIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="104px" height="12px" viewBox="0 0 104 12">
      <path d="M36.1,0c-2.3,0-4.1,2-4.1,4.4c0,2.4,1.9,4.4,4.1,4.4c1.7,0,3.2-1.1,3.8-2.7h-2.3c-0.4,0.4-1,0.7-1.6,0.7
        c-1.1,0-2-0.8-2.2-1.9h0h6.3c0-0.2,0-0.3,0-0.5C40.2,2,38.4,0,36.1,0z M34.1,3.4c0.4-0.8,1.1-1.3,2-1.3c0.9,0,1.7,0.6,2,1.3H34.1z
         M25.4,4.4v4.3h1.9V4.4c0-1.3,1-2.4,2.2-2.4h1V0h-1C27.3,0,25.4,2,25.4,4.4z M14.9,6.6c-0.4,0.1-0.8,0.1-1.2,0.1
        c-1.2,0-2.2-1.1-2.2-2.4V0H9.5v4.4c0,2.4,1.9,4.4,4.1,4.4c1.4,0,2.4-0.1,3.1-0.4V0h-1.9V6.6z M4.2,2c0.9,0,1.7,0.6,2,1.3h2
        C7.9,1.4,6.2,0,4.2,0C1.9,0,0,2,0,4.4c0,2.4,1.9,4.4,4.2,4.4c1.9,0,3.6-1.4,4.1-3.2H6.2c-0.4,0.7-1.1,1.2-2,1.2
        C3,6.7,1.9,5.7,1.9,4.4S3,2,4.2,2z M19.1,4.4v4.3H21V4.4c0-1.3,1-2.4,2.2-2.4h1V0h-1C20.9,0,19.1,2,19.1,4.4z M44.7,0
        c-1.4,0-2.4,0.1-3.1,0.4v8.4h1.9V2.2C43.9,2.1,44.3,2,44.7,2c1.2,0,2.2,1.1,2.2,2.4v4.4h1.9V4.4C48.8,2,47,0,44.7,0z M58.4,3.4
        C58,1.4,56.3,0,54.3,0c-2.3,0-4.2,2-4.2,4.4c0,2.4,1.9,4.4,4.2,4.4c1.9,0,3.6-1.4,4.1-3.2h-2.1c-0.4,0.7-1.1,1.2-2,1.2
        c-1.2,0-2.3-1.1-2.3-2.4s1-2.4,2.3-2.4c0.9,0,1.7,0.6,2,1.3l0,0H58.4z M93.5,0c-1.4,0-2.4,0.1-3.1,0.4v8.4h1.9V2.2
        C92.7,2.1,93.1,2,93.5,2c1.2,0,2.2,1.1,2.2,2.4v4.4h1.9V3c0-0.4,0.6-0.9,1.2-0.9v-2c-1,0-1.9,0.4-2.5,1.2C95.7,0.6,94.6,0,93.5,0z
         M98.9,0.1v2c1.2,0,2.2,1.1,2.2,2.4v4.4h1.9V4.5C103.1,2.1,101.2,0.1,98.9,0.1z M85,0c-2.3,0-4.1,2-4.1,4.4c0,2.4,1.9,4.4,4.1,4.4
        s4.1-2,4.1-4.4S87.3,0,85,0z M85,6.7c-1.2,0-2.2-1.1-2.2-2.4S83.8,2,85,2c1.2,0,2.2,1.1,2.2,2.4C87.2,5.7,86.2,6.7,85,6.7z M75.6,2
        c0.9,0,1.7,0.6,2,1.3h2C79.3,1.4,77.6,0,75.6,0c-2.3,0-4.2,2-4.2,4.4c0,2.4,1.9,4.4,4.2,4.4c1.9,0,3.6-1.4,4.1-3.2h-2.1
        c-0.4,0.7-1.1,1.2-2,1.2c-1.2,0-2.3-1.1-2.3-2.4S74.4,2,75.6,2z M68.7,3.4c-0.6,0-1,0.5-1,1.1c0,0.6,0.5,1.1,1,1.1
        c0.6,0,1-0.5,1-1.1C69.8,3.9,69.3,3.4,68.7,3.4z M65.6,0l-2.3,5.7l-2-5.7H59l3.2,8.3L60.7,12h2.2l4.9-12H65.6z" fill="currentColor"/>
      </svg>
    );
  },
};

const Icon = (props) => {
  if (!props || !icons[props.iconType + 'Icon']) return null;

  return icons[props.iconType + 'Icon'](props);
};

Icon.propTypes = {
  /**
   * Icon type
   */
  iconType: PropTypes.string.isRequired,

  /**
   * Icon size
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  width: PropTypes.string,

  height: PropTypes.string,

  fill: PropTypes.string,
};

export default Icon;
