/**
 * Input manager to handle inputs with validation
 */
class InputManager {
  /**
   * InputManager constructor. NOTE: initialize it AFTER creating a state object in provided component
   * @param component {object} Component which manager is mounted in
   * @param component.state {object} Component's state
   * @param options {object} Options
   * @param options.inputs {array.<string>} Names of inputs to be handled
   * @param options.initialValues {object} Initial values for inputs
   * @param options.addToInitialState {object} Data to add to initial state
   * @example
   * // Initialize inputManager
   * this.inputManager = new InputManager(this, {
   *   inputs: [],
   * });
   */
  constructor(component, options = {}) {
    this.component = component;
    this.options = options;

    const addToState = options.addToInitialState || {};

    this.component.state = {
      ...this.component.state,
      ...this.createFormInitialState(options.initialValues),
      ...addToState,
    };
  }

  createFormInitialState(initialValues = {}) {
    if (!this.options.inputs || !this.options.inputs.length) {
      return console.error('Provide `inputs` to InputManager options');
    }

    return this.options.inputs.reduce((result, input) => {
      result[this.getInputValueKey(input)] = initialValues[input] || '';

      return result;
    }, {});
  }

  // ;;events --------------------------------------------------------------------------------------

  /**
   * Handle input change
   * @param e {object} Event object
   * @param [cb] {function} Callback (fires after state is applied)
   */
  handleChange = (e, cb) => {
    if (!e.target.name) return console.error('Provide `name` for input');
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setValue(e.target.name, value, cb);
  };

  // ;;public --------------------------------------------------------------------------------------

  getValue = inputName => this.component.state[this.getInputValueKey(inputName)];

  /**
   * Set value to state
   * @param inputName {string} Input name
   * @param value {string} Input value
   * @param [cb] {function} Callback (fires after state is applied)
   */
  setValue = (inputName, value, cb) => {
    this.component.setState({
      [this.getInputValueKey(inputName)]: value,
    }, cb);
  };

  // ;;inner ---------------------------------------------------------------------------------------

  getInputValueKey(inputName) {
    return `${inputName}InputValue`;
  }
}

export default InputManager;
