import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import axios from 'axios';
import Reaptcha from 'reaptcha';
import Cookies from 'js-cookie';

// Config
import { railsConfig, urls, texts } from '../../config';

// Services
import InputManager from '../../../../frontend-base/js/services/input-manager';

// Components
import Button from '../../../../frontend-base/js/components/button/button';
import ButtonWithIcon from '../../../../frontend-base/js/components/button-with-icon/button-with-icon';
import CheckboxText from '../../../../frontend-base/js/components/checkbox-text/checkbox-text';
import Divider from '../../../../frontend-base/js/components/divider/divider';
import Icon from '../../../../frontend-base/js/components/icon/icon';
import Input from '../../../../frontend-base/js/components/input/input';
import InputGroup from '../../../../frontend-base/js/components/input-group/input-group';
import InputWithButton from '../../../../frontend-base/js/components/input-with-button/input-with-button';
import IslandTitle from '../../../../frontend-base/js/components/island-title/island-title';
import Message from '../../../../frontend-base/js/components/message/message';
import IconButton from '../../../../frontend-base/js/components/icon-button/icon-button';
import SwitchButtons from '../../../../frontend-base/js/components/switch-buttons/switch-buttons';

class SignUpForm extends React.Component {

  static propTypes = {
  };

  inputNames = {
    acceptTerms: `user[${Cookies.get('location')}_terms_accepted]`,
    subscription: 'user[subscription_required]',
    email: 'email',
    password: 'password',
    username: 'user[username]',
    phone: 'user[requested_phone]',
    role: 'user[role]'
  };

  $recaptcha = React.createRef();
  $phoneInput = React.createRef();

  constraints = this.createConstraints();

  state = {
    processing: false,
    message: null,
    showPass: false,
    recaptchaLoaded: false,
  };

  inputManager = new InputManager(this, {
    inputs: [
      this.inputNames.acceptTerms,
      this.inputNames.email,
      this.inputNames.password,
      this.inputNames.username,
      this.inputNames.phone,
      this.inputNames.subscription,
      this.inputNames.role,
    ],
    initialValues: {
      [this.inputNames.subscription]: true,
    },
  });

  componentDidMount() {
    this.inputManager.setValue(this.inputNames.role, this.isHr());
  }

  // ;;events --------------------------------------------------------------------------------------

  handleLoginGoogle = () => {
    window.location = urls.api.google;
  };

  handleShowPass = () => {
    this.setState({ showPass: !this.state.showPass });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('handleSubmit', '85')

    if (!this.customValidationUsername() && this.isFormValid()) {
      this.showError(texts.errors.usernameTooLong);

      return
    }

    if (!this.customValidationPhone() && this.isFormValid()) {
      this.showError(texts.errors.phoneTooLong);

      return
    }

    this.setState({
      message: null,
    })
    console.log('handleSubmit', '102')
    if (!this.state.recaptchaLoaded) return this.showError();
    console.log('handleSubmit', '104')

    const errors = this.checkFormValidity();

    if (errors) return this.showError(errors[0]);

    console.log('handleSubmit', '110')
    this.$recaptcha.current.execute();
    console.log('handleSubmit', '112')
  };

  handleRecaptchaLoad = () => {
    this.setState({ recaptchaLoaded: true });
  };

  verifyCaptchaCallback = (response) => {
    console.log('verifyCaptchaCallback', '120')
    this.sendForm(response);
    console.log('verifyCaptchaCallback', '122')
  };

  // ;;inner ---------------------------------------------------------------------------------------

  async sendForm(recaptchaToken) {
    this.setState({
      processing: true,
    });
    console.log('sendForm', '131')
    try {
      console.log('sendForm', '133')
      const response = await axios.post(urls.api.signUp, {
        user: {
          email: this.inputManager.getValue(this.inputNames.email),
          password: this.inputManager.getValue(this.inputNames.password),
          local_terms_accepted: this.getValueCheckboxBool(this.inputManager.getValue('user[local_terms_accepted]')),
          global_terms_accepted: this.getValueCheckboxBool(this.inputManager.getValue('user[global_terms_accepted]')),
          subscription_required: this.getValueCheckboxBool(this.inputManager.getValue(this.inputNames.subscription)),
          role: this.inputManager.getValue(this.inputNames.role),
          username: this.inputManager.getValue(this.inputNames.username),
          requested_phone: this.inputManager.getValue(this.inputNames.phone).substring(1),
          recaptcha_token: recaptchaToken,
        },
      });

      const error = response.data?.error?.message;

      if (error) throw error;

      const redirectUrl = response.data?.success?.redirect_url;

      let action = '';
      let badPhone = '';

      if (response.data?.success?.action == 'showCodeForm') {
        action = '?' + response.data?.success?.action;
      }

      if (response.data?.success?.bad_phone !== null) {
        badPhone = '?bad_phone=' + response.data?.success?.bad_phone;
      }

      if (redirectUrl) window.location = redirectUrl + action + badPhone;
    } catch (e) {
      console.log('sendForm', '166')

      if (e?.response?.status && e?.response?.status == 500) {
        window.location = railsConfig.errorPageUrl + '?' + 'status=500';
      } else {
        this.showError(e.response?.data?.error?.message || e);
      }

      this.$recaptcha.current.reset();
      console.log(e, '169')
    }
  }

  showError(msg) {
    this.setState({
      processing: false,
      message: {
        content: typeof msg === 'string' ? msg : texts.errors.default,
        error: true,
      },
    });
  }

  // ;;compute -------------------------------------------------------------------------------------

  isHr = () => {
    if (window.location.href.indexOf('hr_required=true') > 0) return 'hr';

    return '';
  }

  createConstraints() {
    return {
      email: {
        email: {
          message: texts.errors.invalidEmail,
        },
      },
      password: {
        length: {
          minimum: 1,
          message: texts.errors.emptyPassword,
        },
      },
      acceptTerms: {
        inclusion: {
          within: [true],
          message: texts.errors.acceptTerms,
        },
      },
      // username: {
      //   length: {
      //     maximum: 20,
      //     message: texts.errors.usernameTooLong,
      //   },
      // },
      // phone: {
      //   length: {
      //     maximum: 20,
      //     message: texts.errors.phoneTooLong,
      //   },
      // },
    }
  }

  checkFormValidity() {
    return validate(
      {
        email: this.inputManager.getValue(this.inputNames.email),
        password: this.inputManager.getValue(this.inputNames.password),
        acceptTerms: this.inputManager.getValue(this.inputNames.acceptTerms),
        subscription: this.inputManager.getValue(this.inputNames.subscription),
        hr: this.inputManager.getValue(this.inputNames.hr),
        username: this.inputManager.getValue(this.inputNames.username),
        phone: this.inputManager.getValue(this.inputNames.phone),
      },
      this.constraints,
      {
        format: 'flat',
        fullMessages: false,
      }
    );
  }

  customValidationPhone = () => {
    let phone = this.inputManager.getValue(this.inputNames.phone).length;
    if (phone <= 15 & phone >= 9 || phone == 0) return true;

    return false;
  }

  customValidationUsername = () => {
    if (this.inputManager.getValue(this.inputNames.username).length <= 20) return true;

    return false;
  }

  isFormValid() {
    return !this.checkFormValidity();
  }

  getPassIcon() {
    return this.state.showPass ? 'eyeOpen' : 'eyeClose';
  }

  getPassType() {
    return this.state.showPass ? 'text' : 'password';
  }

  handleChangePhone = (e) => {
    if (!/^[+]{1}[0-9]*$/.test(e.target.value)) return null;

    this.inputManager.handleChange(e);
  }

  autoCompleteUsernameFromEmail = () => {
    let email = this.inputManager.getValue(this.inputNames.email);
    this.inputManager.setValue(this.inputNames.username, '');

    this.setUsernameFromEmail(email);
  }

  setUsernameFromEmail = (email) => {
    let username = '';

    if (email.indexOf('@') > 0)  {
      username = email.split('@')[0];
    }

    // maxlength 20
    if (username.length > 20) {
      username = username.substr(0, 20);
    }

    this.inputManager.setValue(this.inputNames.username, username);
  }

  getValueCheckboxBool = (valueCheckbox) => {
    return Boolean(valueCheckbox);
  }

  handleFocus = () => {
    this.setOnFocusValuePhone(() => {
      this.setCaretAtEnd(this.$phoneInput.current.$input.current);
    })
  }

  setOnFocusValuePhone = (callback) => {
    if (this.inputManager.getValue(this.inputNames.phone).length == 0) {
      this.inputManager.setValue(this.inputNames.phone, '+');
    }
    setTimeout(() => {
      callback();
    }, 0);
  }

  clearValueIfOnlyPlus = () => {
    if (this.inputManager.getValue(this.inputNames.phone).length == 1) {
      this.inputManager.setValue(this.inputNames.phone, '');
    }
  }

  setCaretAtEnd = (elem) => {
    var elemLen = elem.value.length;
    // For IE Only
    if (document.selection) {
        // Set focus
        elem.focus();
        // Use IE Ranges
        var oSel = document.selection.createRange();
        // Reset position to 0 & then set at end
        oSel.moveStart('character', -elemLen);
        oSel.moveStart('character', elemLen);
        oSel.moveEnd('character', 0);
        oSel.select();
    }
    else if (elem.selectionStart || elem.selectionStart == '0') {
        // Firefox/Chrome
        elem.selectionStart = elemLen;
        elem.selectionEnd = elemLen;
        elem.focus();
    }
  }

  // ;;render --------------------------------------------------------------------------------------


  renderMessage() {
    if (!this.state.message) return null;

    return (
      <React.Fragment>
        <Message {...this.state.message} />
        <Divider height={2} />
      </React.Fragment>
    );
  }

  renderInputs() {
    return (
      <InputGroup error={this.state.message?.error}>
        {this.renderInputEmail()}
        {this.renderInputPassword()}
        {this.rendetInputUsername()}
      </InputGroup>
    );
  }

  renderInputEmail = () => {
    return (
      <Input
        handleChange={this.inputManager.handleChange}
        onBlur={this.autoCompleteUsernameFromEmail}
        name={this.inputNames.email}
        placeholder="Электронная почта"
        value={this.inputManager.getValue(this.inputNames.email)}
        autoFocus
      />
    )
  }

  renderInputPassword = () => {
    return (
      <InputWithButton>
        <Input
          handleChange={this.inputManager.handleChange}
          name={this.inputNames.password}
          placeholder="Пароль"
          type={this.getPassType()}
          value={this.inputManager.getValue(this.inputNames.password)}
          autoComplete="new-password"
          withButton
        />
        <IconButton
          handleClick={this.handleShowPass}
          iconType={this.getPassIcon()}
          insideInput
          title="Показать пароль"
        />
      </InputWithButton>
    )
  }

  rendetInputUsername = () => {
    return (
      <Input
        handleChange={this.inputManager.handleChange}
        name={this.inputNames.username}
        placeholder="Юзернейм"
        value={this.inputManager.getValue(this.inputNames.username)}
        autoComplete='off'
      />
    )
  }

  renderInputPhoneWithHelpButton = () => {
    return (
      <InputWithButton>
        <Input
          ref={this.$phoneInput}
          onFocus = {this.handleFocus}
          onBlur = {this.clearValueIfOnlyPlus}
          handleChange={this.handleChangePhone}
          name={this.inputNames.phone}
          value={this.inputManager.getValue(this.inputNames.phone)}
          placeholder="Номер мобильного телефона"
          withButton
        />
        <IconButton
          absoluteUrl
          iconType="help"
          target="_blank"
          url={urls.idInfoUrl}
          insideInput
         />
      </InputWithButton>
    )
  }

  renderSwitchButtons() {
    const buttons = [
      {
        text: 'Я работодатель',
        value: 'hr',
      },
      {
        text: 'Я соискатель',
        value: 'applicant',
      },
    ];

    return (
      <div data-gtm-track="role-user">
        <SwitchButtons
          handleChange={this.inputManager.handleChange}
          handleUncheck={() => this.inputManager.setValue(this.inputNames.role, '')}
          name={this.inputNames.role}
          value={this.inputManager.getValue(this.inputNames.role)}
          buttons={buttons}
          primary
        />
      </div>
    );
  }

  renderCheckbox(name, text, link) {
    return (
      <CheckboxText
        handleChange={this.inputManager.handleChange}
        name={name}
        checked={Boolean(this.inputManager.getValue(name))}>
        {text}
        {' '}
        {this.renderLinkForCheckbox(link)}
      </CheckboxText>
    );
  }

  renderLinkForCheckbox = (link) => {
    if (link) return <a href={urls.agreementUrl} rel="noopener" target="_blank">{link}</a>

    return null;
  }

  renderSubmitButton() {
    return (
      <Button disabledStyle={!this.isFormValid()} processing={this.state.processing} type="submit">
        Зарегистрироваться
      </Button>
    );
  }

  renderSocialButtons() {
    return ([
      <Divider height={3} key="divider02"/>,
      <ButtonWithIcon key="btn01">
        <Button handleClick={this.handleLoginGoogle} secondary>
          Войти с помощью Google
        </Button>
        <Icon iconType="google" />
      </ButtonWithIcon>
    ]);
  }

  renderBr = () => {
    return (
      <br />
    )
  }

  renderAcceptTermsCheckbox = () => {
    return (
      <CheckboxText
        handleChange={this.inputManager.handleChange}
        name={this.inputNames.acceptTerms}
        checked={Boolean(this.inputManager.getValue(this.inputNames.acceptTerms))}>
        Принимаю условия <a href={urls.agreementUrl} rel="noopener" target="_blank">пользовательского соглашения</a> и <a href={urls.privacyPolicyUrl} rel="noopener" target="_blank">политики конфиденциальности</a>
      </CheckboxText>
    )
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        {this.renderMessage()}
        {this.renderInputs()}
        <Divider height={3} />
        {this.renderSwitchButtons()}
        <Divider height={3} />
        {this.renderCheckbox(this.inputNames.subscription, "Получать рассылки dev.by")}
        <Divider height={3} />
        {this.renderAcceptTermsCheckbox()}
        <Divider height={3} />
        {this.renderSubmitButton()}
        {this.renderSocialButtons()}
        <Reaptcha
          hl="ru"
          onLoad={this.handleRecaptchaLoad}
          onVerify={this.verifyCaptchaCallback}
          ref={this.$recaptcha}
          sitekey={railsConfig.recaptcha_key}
          size="invisible"
        />
      </form>
    );
  }
}

export default SignUpForm;
