import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import IconButton from '../icon-button/icon-button';

class HeaderIconButton extends React.Component {
  static propTypes = {
    /**
     * Use absolute link
     */
    absoluteUrl: PropTypes.bool,

    /**
     * Handler for button click
     */
    handleClick: PropTypes.func,

    /**
     * IconType props for Icon component
     */
    iconType: PropTypes.string,

    /**
     * If button view inside input field
     */
    insideInput: PropTypes.bool,

    /**
     * Button type
     */
    type: PropTypes.string,

    /**
     * Link URL
     */
    url: PropTypes.string,
  };

  render() {
    return (
      <div className={classNames({
        'header-icon-button': true,
        'header-icon-button_inside-input': this.props.insideInput,
      })}>
        <IconButton
          absoluteUrl={this.props.absoluteUrl}
          handleClick={this.props.handleClick}
          iconType={this.props.iconType}
          insideInput={this.props.insideInput}
          isBlue
          url={this.props.url}
          type={this.props.type}
        />
      </div>
    );
  }
}

export default HeaderIconButton;
