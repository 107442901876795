import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class IslandText extends React.Component {
  static propTypes = {
    xl: PropTypes.bool,
    lg: PropTypes.bool,
    sm: PropTypes.bool,
    insidePopover: PropTypes.bool,
    insideFormGroup: PropTypes.bool,
    secondary: PropTypes.bool,
    remark: PropTypes.bool,
    remarkBig: PropTypes.bool,
    bold: PropTypes.bool,
    /**
     * Button with margin bottom on small screens
     */
    marginBottom: PropTypes.bool,
  };

  render() {
    return (
      <div className={classNames({
        'island-text': true,
        'island-text_bold': this.props.bold,
        'island-text_black': this.props.black,
        'island-text_xl': this.props.xl,
        'island-text_lg': this.props.lg,
        'island-text_sm': this.props.sm,
        'island-text_popover': this.props.insidePopover,
        'island-text_secondary': this.props.secondary,
        'island-text_remark': this.props.remark,
        'island-text_form-group': this.props.insideFormGroup,
        'island-text_margin-bottom': this.props.marginBottom,
        'island-text_remark-big': this.props.remarkBig,
      })}>
        {this.props.children}
      </div>
    );
  }
}

export default IslandText;
