import queryString from 'query-string';
import { animateScroll as scroll } from 'react-scroll';

let navigator;

class Navigator {
  constructor(router) {
    this.router = router;
    this.routeChangeListeners = [];
    this.lastScrollPosition = 0;
    this.isBackAction = false;

    this.addListeners();
    this.currentRoute = router.location;
  }

  // -----------------------------------------------------------------------------------------

  addListeners() {
    this.router.history.listen((route, action) => {
      const { options = {} } = route;

      this.isBackAction = action === 'POP';

      const leavingRoute = Object.assign({}, this.currentRoute);

      // Save scroll position to restore it after back action (ignore saving if it is already back action)
      if (!this.isBackAction) {
        this.lastScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      }

      this.currentRoute = {
        ...route,
        origin: window.location.origin,
        href: window.location.origin + route.pathname,
      };

      // Call listeners on next tick
      setTimeout(() => {
        for (const listener of this.routeChangeListeners) {
          listener.fn(route, leavingRoute);
        }
      });
    });
  }

  /**
   * Listen to route change
   *
   * @param listener {object} Listener object with required structure:
   * ```
   * {
   *   // Listener callback
   *   fn: {function},
   *   // Listener name
   *   name: {string}
   * }
   * ```
   */
  listen(listener) {
    if (!listener) return;

    this.routeChangeListeners.push(listener);
  }

  stopListening(name) {
    this.routeChangeListeners = this.routeChangeListeners.filter(listener => listener.name !== name);
  }

  // -----------------------------------------------------------------------------------------

  resetScroll() {
    scroll.scrollToTop({
      duration: 0,
    });
  }

  restoreScroll() {
    // Restore scroll position
    if (this.isBackAction && this.lastScrollPosition) {
      scroll.scrollTo(this.lastScrollPosition, {
        duration: 0,
      });
      this.lastScrollPosition = 0;
    }
  }

  // -----------------------------------------------------------------------------------------

  /**
   *
   * @param route
   * @param params
   * @param options {object}
   * @param options.rawQuery {boolean} Consider params as a raw query string
   * @param options.replace {boolean} Use replace instead of push
   * @param options.hash {string} Hash string
   */
  goTo(route, params, options = {}) {
    const to = {
      pathname: route || this.getCurrentPath(),
      search: options.rawQuery ? params : queryString.stringify(Object.assign({}, params)),
      hash: options.hash || undefined,
      options,
    };
    this.router.history[options.replace ? 'replace' : 'push'](to);
  }

  goBack() {
    this.router.history.goBack();
  }

  getCurrentState() {
    return this.router.history.location.state;
  }

  getCurrentRoute() {
    return this.currentRoute;
  }

  getCurrentLocation() {
    return this.router.history.location;
  }

  getCurrentHash() {
    const hash = this.router.history.location.hash;
    return hash ? hash.slice(1) : '';
  }

  getCurrentPath() {
    const currentPath = this.router.history.location.pathname;

    if (currentPath[currentPath.length - 1] === '/' && currentPath.length > 1) {
      return currentPath.slice(currentPath, currentPath.length - 1);
    }

    return currentPath;
  }

  getCurrentSearch() {
    const search = this.router.history.location.search;
    return search ? search.slice(1) : '';
  }

  getCurrentQuery() {
    return queryString.parse(this.router.history.location.search || '');
  }

  setQuery(query, options) {
    const path = this.getCurrentPath();
    const currentQuery = this.getCurrentQuery();
    const nextQuery = query ? Object.assign(currentQuery, query) : {};

    navigator.goTo(path, nextQuery, options);
  }

  clearSearch(options) {
    this.setQuery(null, {
      noProcessing: true,
      noLoad: true,
      ...options,
    });
  }

  setHash(hash) {
    history.pushState ? history.pushState(null, null, `#${hash}`) : (location.hash = `#${hash}`);
  }
}

const NavigatorConstructor = {
  init(router, actions) {
    navigator = new Navigator(router, actions);
  },
};

export { NavigatorConstructor, navigator };
