import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from '../icon/icon';
import Spinner from '../spinner/spinner';

class ButtonToBack extends React.Component {
  static defaultProps = {
    type: 'button',
  };

  constructor(props) {
    super(props);
    this.createUrl = this.createUrl.bind(this);
  }

  // ;;compute -------------------------------------------------------------------------------------

  getUrlPrevPage() {
    let urlPrevPage = document.referrer;
    if (urlPrevPage.indexOf('?') > 0) {
      return urlPrevPage.split('?')[0];
    }
    return urlPrevPage;
  }

  getJobApplicationIdFromCurrentPage() {
    return window.location.search.split('&')[1];
  }

  createUrl() {
    return this.getUrlPrevPage() + '?' + this.getJobApplicationIdFromCurrentPage() + '&show_response_popup=true';
  }

  goBack = (e) => {
    e.preventDefault();
    window.history.back();
  }

  // ;;render --------------------------------------------------------------------------------------

  renderLinkToBack = () => {
    if (this.props.isFullFormWithToBack) {
      return (
        <a
            onClick = {this.goBack}
            className="button-to-back__button"
          >
            <Icon iconType="arrowPrev" />
        </a>
      )
    }

    return (
      <a
        href={this.createUrl()}
        className="button-to-back__button"
      >
        <Icon iconType="arrowPrev" />
      </a>
    )
  }

  renderButton() {
    return (
        <div className="button-to-back">
            {this.renderLinkToBack()}
            <span className="button-to-back__text">
                {this.props.children}
            </span>
        </div>
    );
  }

  render() {
    return this.renderButton();
  }
}

export default ButtonToBack;
