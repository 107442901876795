import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js/validate';
import axios from 'axios';

// Config
import { urls, texts } from '../../config';

// Services
import InputManager from '../../../../frontend-base/js/services/input-manager';
import { navigator } from '../../../../frontend-base/js/services/navigator';

// Components
import Button from '../../../../frontend-base/js/components/button/button';
import Divider from '../../../../frontend-base/js/components/divider/divider';
import IslandText from '../../../../frontend-base/js/components/island-text/island-text';
import Input from '../../../../frontend-base/js/components/input/input';
import InputGroup from '../../../../frontend-base/js/components/input-group/input-group';
import Message from '../../../../frontend-base/js/components/message/message';
import IslandTitle from '../../../../frontend-base/js/components/island-title/island-title';

class NewPassForm extends React.Component {
  static propTypes = {};

  actions = {
    showResetLink: 'showResetLink',
  };

  inputNames = {
    password: 'password',
    passwordConfirm: 'passwordConfirm',
  };

  constraints = {
    password: {
      length: {
        minimum: 6,
        message: texts.errors.invalidPassword,
      },
    },
    passwordConfirm: {
      equality: {
        attribute: 'password',
        message: texts.errors.nonMatchingPassword,
      },
    },
  };

  state = {
    processing: false,
    message: null,
    success: null,
  };

  inputManager = new InputManager(this, {
    inputs: [this.inputNames.password, this.inputNames.passwordConfirm],
  });

  // ;;events --------------------------------------------------------------------------------------

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.checkFormValidity();

    if (errors) return this.showError(errors[0]);

    this.sendForm();
  };

  // ;;inner ---------------------------------------------------------------------------------------

  async sendForm() {
    this.setState({
      processing: true,
    });

    try {
      const response = await axios.put(urls.api.newPassword, {
        user: {
          password: this.inputManager.getValue(this.inputNames.password),
          password_confirmation: this.inputManager.getValue(this.inputNames.passwordConfirm),
          reset_password_token: navigator.getCurrentQuery().reset_password_token,
        },
      });

      const error = response.data?.error;

      if (error) throw error;

      this.showSuccess({ message: texts.success.passwordChanged });
    } catch (e) {
      this.showError(e.response?.data?.error?.message || e.message, e.action);
    }
  }

  showSuccess(data) {
    if (!data) return;

    this.setState({
      processing: false,
      success: {
        message: data.message,
      },
    });
  }

  showError(msg, action) {
    this.setState({
      processing: false,
      message: {
        content: typeof msg === 'string' ? msg : texts.errors.default,
        error: true,
        action,
      },
    });
  }

  // ;;compute -------------------------------------------------------------------------------------

  checkFormValidity() {
    return validate(
      {
        password: this.inputManager.getValue(this.inputNames.password),
        passwordConfirm: this.inputManager.getValue(this.inputNames.passwordConfirm),
      },
      this.constraints,
      {
        format: 'flat',
        fullMessages: false,
      }
    );
  }

  isFormValid() {
    return !this.checkFormValidity();
  }

  // ;;render --------------------------------------------------------------------------------------

  renderMessageAction(action) {
    if (action === this.actions.showResetLink) {
      return (
        <React.Fragment>
          <Divider height={1} />
          <Button insideMessage special url={urls.routes.resetPassword}>
            Восстановить пароль
          </Button>
        </React.Fragment>
      );
    }

    return null;
  }

  renderMessageContent() {
    return (
      <React.Fragment>
        {this.state.message.content}
        {this.renderMessageAction(this.state.message.action)}
      </React.Fragment>
    );
  }

  renderMessage() {
    if (!this.state.message) return null;

    return (
      <React.Fragment>
        <Message
          content={this.renderMessageContent()}
          error={this.state.message.error}
          warning={this.state.message.warning}
        />
        <Divider height={2} />
      </React.Fragment>
    );
  }

  renderTitle() {
    return <IslandTitle>Восстановление пароля</IslandTitle>;
  }

  renderText() {
    return <IslandText>Введите новый пароль</IslandText>;
  }

  renderInputs() {
    return (
      <InputGroup error={this.state.message?.error}>
        <Input
          handleChange={this.inputManager.handleChange}
          name={this.inputNames.password}
          placeholder="Пароль"
          type="password"
          value={this.inputManager.getValue(this.inputNames.password)}
        />
        <Input
          handleChange={this.inputManager.handleChange}
          name={this.inputNames.passwordConfirm}
          placeholder="Повторите пароль"
          type="password"
          value={this.inputManager.getValue(this.inputNames.passwordConfirm)}
        />
      </InputGroup>
    );
  }

  renderSubmitButton() {
    return (
      <Button disabledStyle={!this.isFormValid()} processing={this.state.processing} type="submit">
        Сохранить
      </Button>
    );
  }

  renderFormContent() {
    if (this.state.success) return null;

    return (
      <React.Fragment>
        {this.renderText()}
        <Divider height={2} />
        {this.renderInputs()}
        <Divider height={2} />
        {this.renderSubmitButton()}
      </React.Fragment>
    );
  }

  renderSuccess() {
    if (!this.state.success) return null;

    return (
      <React.Fragment>
        <IslandText>{this.state.success.message}</IslandText>
        <Divider height={3} />
        <Button
          absoluteUrl
          handleClick={this.handleResendClick}
          processing={this.state.resendProcessing}
          secondary
          rel='noreferrer'
          url={`/@${urls.routes.signIn}`}>
          Вход
        </Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderMessage()}
        {this.renderTitle()}
        <Divider height={2} />
        {this.renderFormContent()}
        {this.renderSuccess()}
      </form>
    );
  }
}

export default NewPassForm;
