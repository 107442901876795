import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Message extends React.Component {
  static propTypes = {
    /**
     * Content of message
     */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    /**
     * Error state
     */
    error: PropTypes.bool,

    /**
     * Warning state
     */
    warning: PropTypes.bool,
  };

  render() {
    return (
      <span
        className={classNames({
          message: true,
          message_error: this.props.error,
          message_warning: this.props.warning,
        })}>
        {this.props.content}
      </span>
    );
  }
}

export default Message;
