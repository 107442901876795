import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from '../icon/icon';

class Checkbox extends React.Component {
  static propTypes = {

    /**
     * Check state
     */
    checked: PropTypes.bool,

    /**
     * Disabled state
     */
    disabled: PropTypes.bool,

    /**
     * Handler for input change event
     */
    handleChange: PropTypes.func,

    /**
     * Input ID
     */
    id: PropTypes.string,

    /**
     * Input name
     */
    name: PropTypes.string,
  };

  // ;;events --------------------------------------------------------------------------------------

  handleChange = (e) => {
    this.props.handleChange(e);
  };

  // ;;render --------------------------------------------------------------------------------------

  render() {
    return (
      <label className="checkbox">
        <input
          checked={this.props.checked}
          disabled={this.props.disabled}
          className="checkbox__input"
          id={this.props.id}
          name={this.props.name}
          onChange={this.handleChange}
          type="checkbox"
        />
        <div className="checkbox__box">
          <Icon iconType="check" />
        </div>
      </label>
    );
  }
}

export default Checkbox;
