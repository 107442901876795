import React from 'react';
import PropTypes from 'prop-types';

// Components
import Checkbox from '../checkbox/checkbox';

class CheckboxText extends React.Component {
  static propTypes = {

    /**
     * Checked state
     */
    checked: PropTypes.bool,

    /**
     * Disabled state
     */
    disabled: PropTypes.bool,

    /**
     * Handler for input change event
     */
    handleChange: PropTypes.func,

    /**
     * Input name
     */
    name: PropTypes.string.isRequired,
  };

  // ;;events --------------------------------------------------------------------------------------

  handleChange = (e) => {
    this.props.handleChange(e);
  };

  // ;;render --------------------------------------------------------------------------------------

  render() {
    return (
      <div className="checkbox-text">
        <Checkbox
          checked={this.props.checked}
          disabled={this.props.disabled}
          handleChange={this.handleChange}
          id={this.props.name}
          name={this.props.name}
        />
        <label
          className="checkbox-text__text"
          htmlFor={this.props.name}>
          {this.props.children}
        </label>
      </div>
    );
  }
}

export default CheckboxText;