import React from 'react';

// Config
import { urls } from '../config';

// Components
import AuthPage from '../pages/auth-page/auth-page';
import SignInForm from '../components/sign-in-form/sign-in-form';
import SignUpForm from '../components/sign-up-form/sign-up-form';
import ResetPassForm from '../components/reset-pass-form/reset-pass-form';
import NewPassForm from '../components/new-pass-form/new-pass-form';
import GoogleSignUpPage from '../pages/google-sign-up-page/sign-up-page';
import AcceptTermsPage from '../pages/accept-terms-page/accept-terms-page';

export default [
  {
    path: urls.routes.acceptTermsPage,
    exact: true,
    component: AcceptTermsPage,
  },
  {
    path: urls.routes.googleSignUpPage,
    exact: true,
    component: GoogleSignUpPage,
  },
  {
    path: [
      urls.routes.signIn,
      urls.routes.signUp,
      urls.routes.resetPassword,
      urls.routes.newPassword,
    ],
    component: AuthPage,
    routes: [
      {
        path: urls.routes.signIn,
        exact: true,
        component: SignInForm,
      },
      {
        path: urls.routes.signUp,
        exact: true,
        component: SignUpForm,
      },
      {
        path: urls.routes.resetPassword,
        exact: true,
        component: ResetPassForm,
      },
      {
        path: urls.routes.newPassword,
        exact: true,
        component: NewPassForm,
      },
    ],
  },
];
