import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

// Services
import InputManager from '../../services/input-manager';

// HOC
import OnClickOutside from '../../service-components/on-click-outside';

// Components
import Input from '../input/input';
import HeaderIconButton from '../header-icon-button/header-icon-button';
import InputWithButton from '../input-with-button/input-with-button';

class HeaderSearch extends React.Component {
  static propTypes = {
    /**
     * Google search key
     */
    googleSearchKey: PropTypes.string,

    /**
     * True if HeaderSearch instance inside popup
     */
    isPopup: PropTypes.bool,
  };

  queryName = 'q';

  constructor() {
    super();

    this.inputManager = new InputManager(this, {
      inputs: [this.queryName],
    });
  }

  sessionStorageKey = '__header-search';

  // ;;life ----------------------------------------------------------------------------------------

  componentDidMount() {
    if (this.getSessionStorageValue()) {
      this.inputManager.setValue(this.queryName, this.getSessionStorageValue());
    }
  }

  // ;;events --------------------------------------------------------------------------------------

  handleChange = (e) => {
    // Save value in sessionStorage
    this.inputManager.handleChange(e, () => this.setSessionStorage());
  };

  handleSubmit = () => {
    sessionStorage.removeItem(this.sessionStorageKey);
  };

  // ;;inner ---------------------------------------------------------------------------------------

  getSessionStorageValue() {
    return sessionStorage.getItem(this.sessionStorageKey);
  }

  setSessionStorage = debounce(() => {
    sessionStorage.setItem(this.sessionStorageKey, this.inputManager.getValue(this.queryName));
  }, 500);

  // ;;render --------------------------------------------------------------------------------------

  render() {
    // TODO:
    // %form{ :class => "form-search", :action => "https://www.google.com/cse", :method => "GET" }
    // .data-open-search
    //     = link_to "Поиск", "#", :class => "a-dotted a-open-search"
    //   -# %input{ :type => "text", :class => "input-large", :name => "q", :placeholder => arr_placeholder[rand(arr_placeholder.size)] }
    // %input{ :type => "text", :class => "input-large", :name => "q", :placeholder => 'Поиск по сайту' }
    // %input{ :name => "cx", :type => "hidden", :value => "017818143311378779828:hkz7dyja5dc"}
    // %input{ :name => "ie", :type => "hidden", :value => "UTF-8"}
    // %input{ :type => "submit", :value => "" }

    return (
      <div
        className={classNames({
          'header-search': true,
          'header-search_popup': this.props.isPopup,
          'events-always': true,
        })}>
        <form
          action="https://www.google.com/cse"
          className="header-search__form"
          onSubmit={this.handleSubmit}
          method="get">
          <InputWithButton>
            <Input
              autoFocus
              handleChange={this.handleChange}
              fullHeight
              placeholder="Поиск"
              type="text"
              name={this.queryName}
              search
              value={this.inputManager.getValue(this.queryName)}
              withButton
            />
            <HeaderIconButton
              iconType="search"
              insideInput
              type="submit"
            />
          </InputWithButton>
          <input type="hidden" name="cx" value={this.props.googleSearchKey} />
          <input type="hidden" name="ie" value="UTF-8" />
        </form>
      </div>
    );
  }
}

export default OnClickOutside(HeaderSearch);
